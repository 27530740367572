@charset "UTF-8";

@import "mixins";

.navigation.localnav_bottom {
  background-color: var(--color-surface-weaker);
  margin-top: var(--margin-top-large);
  @include mq(tbl) {
    margin-top: var(--margin-top-xlarge);
  }
  .inner {
    @include container;
    padding-top: var(--space-xxlarge);
    padding-bottom: var(--space-xxlarge);
    @include mq(tbl) {
      padding-bottom: var(--space-xxxlarge);
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: var(--space-xlarge);
      @include mq(tbl) {
        display: block;
        position: relative;
      }
      h2 {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          font-size: var(--font-size-large);
        }
      }
      /*
      .all {
        display: none;
        font-size: var(--font-size-small);
        @include mq(tbl) {
          position: absolute;
          right: 0;
          top: 15px;
        }
        a {
          position: relative;
          padding-left: 17px;
          &:before {
            @include pd;
          }
          &:before {
            @include pd;
            background: center / contain no-repeat var(--arrow-button);
            width: 16px;
            height: 16px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
      */
    }
    ul {
      @include grid(2,var(--space-medium),var(--space-medium));
      @include mq(tbl) {
        @include grid(3,var(--space-large),var(--space-large));
      }
      li {
        &.active {
          a {
            border-bottom: 2px solid var(--color-brand);
            font-weight: var(--font-weight-bold);
            @include mq(tbl) {
              border-bottom-width: 3px;
            }
            span {
              color: var(--color-brand);
            }
          }
        }
        a {
          border-bottom: 1px solid var(--color-border-strong);
          padding: 0 0 var(--space-xsmall);
          display: block;
          color: inherit;
          @include mq(tbl) {
            @include hoverColor;
          }
          span {
            display: block;
            position: relative;
            padding-left: var(--space-medium);
            font-size: var(--font-size-small);
            line-height: var(--line-height-medium);
            @include mq(tbl) {
              font-size: var(--font-size-medium);
            }
            &:before,&:after {
              @include pd;
            }
            &:before {
              @include pd;
              background: center / contain no-repeat var(--arrow-button);
              width: 8px;
              height: 9px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
