@charset "UTF-8";

@import "mixins";

.navigation.archivenav {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    .overflow {
      overflow: auto;
      padding: 0 0 10px;
      @include mq(tbl) {
        overflow: visible;
        padding: unset;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid var(--color-brand);
        @include mq(tbl) {
          flex-wrap: wrap;
          border-bottom-width: 1px;
        }
        li {
          font-size: var(--font-size-xsmall);
          white-space: nowrap;
          margin-left: var(--space-xxsmall);
          position: relative;
          &:first-child {
            margin-left: 0;
          }
          @include mq(tbl) {
            font-size: var(--font-size-small);
            margin-left: var(--space-xsmall);
          }
          &.active,&.current_page_item {
            z-index: 2;
            a {
              color: var(--color-text-on-accent);
              background-color: var(--color-brand);
              font-weight: var(--font-weight-bold);
              &:before {
                background-color: var(--color-text-on-accent);
                left: 0;
                right: 0;
              }
              &:hover {
                @include mq(tbl) {
                  @include hoverOp(1);
                }
              }
            }
          }
          a {
            display: block;
            position: relative;
            background-color: var(--color-surface-weaker);
            padding: var(--space-small) var(--space-xlarge);
            color: var(--color-text);
            letter-spacing: 0;
            transition: color var(--transition-speed) var(--bezier);
            @include mq(tbl) {
              padding: var(--space-medium) var(--space-xxlarge);
            }
            &:hover {
              @include mq(tbl) {
                @include hoverOp;
              }
            }
          }
        }
      }
    }
  }
}
