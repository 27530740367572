@charset "UTF-8";

@import "mixins";

.component.type_text_lead {
  margin-top: var(--margin-top-small);
  @include mq(tbl) {
    margin-top: var(--margin-top-medium);
  }
  .inner {
    @include container;
    .text {
      p {
        display: block;
        font-size: var(--font-size-medium);
        word-break: auto-phrase;
        @include mq(tbl) {
          font-size: var(--font-size-large);
        }
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
          margin-inline: auto;
          &::before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
        &.right {
          text-align: right;
          &::before {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}
