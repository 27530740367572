@charset "UTF-8";

@import "mixins";

.component.type_extendedtitle {
  position: relative;
  background-color: var(--color-extendedtitle-bg);
  .inner {
    position: relative;
    height: 650px;
    @include mq(tb) {
      height: 550px;
    }
    .text {
      @include container;
      padding-block: 50px;
      position: relative;
      z-index: 2;
      width: 100%;
      span {
        display: none;
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        color: var(--color-text-on-accent);
        font-family: var(--font-family-en);
        margin-top: var(--space-small);
        letter-spacing: .06em;
        @include mq(tbl) {
          font-size: var(--font-size-large);
          max-width: calc(var(--width-container) / 2);
          margin-left: auto;
          margin-top: var(--space-xlarge);
        }
      }
      h1 {
        position: relative;
        color: var(--color-text-on-accent);
        font-size: 4rem;
        line-height: var(--line-height-small);
        font-family: var(--font-family-heading);
        word-break: auto-phrase;
        writing-mode: vertical-rl;
        border: 2px solid var(--color-accent);
        padding: 20px 10px;
        max-height: 550px;
        margin-left: auto;
        @include mq(tbl) {
          border-width: 3px;
        }
      }
    }
    .image {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      img {
        object-fit: cover;
        height: 650px;
        @include mq(tb) {
          height: 550px;
          width: 100%;
        }
      }
    }
  }
}


