@charset "UTF-8";

@import "mixins";

@mixin add-catalog-button {
  
}
@mixin tip {
  z-index: 2;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 36px;
  opacity: 0;
  transition: opacity var(--transition-speed) var(--bezier), transform var(--transition-speed) var(--bezier);
  &::after {
    @include pd;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 7px 4px 0 4px;
    border-color: var(--color-surface) transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
    filter: drop-shadow(0px 3px 6px rgba(0,0,0,.16));
  }
  &.is-show {
    opacity: 1;
    transform: translateY(-5px);
  }
  span {
    display: inline-block;
    background-color: var(--color-surface);
    text-align: center;
    font-size: var(--font-size-xxxsmall);
    color: var(--color-accent);
    padding: var(--space-xxsmall) var(--space-small);
    filter: drop-shadow(0px 3px 6px rgba(0,0,0,.16));
  }
}
@mixin print-button {
  @extend .p_button;
  min-width: 0;
  background-color: var(--color-accent);
  border: none;
  color: var(--color-text-on-accent) !important;
  padding-top: var(--space-medium);
  padding-bottom: var(--space-medium);
  display: block;
  width: 100%;
  font-weight: normal;
  line-height: var(--line-height-small);
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
  @include mq(tbl) {
    margin-left: auto;
    margin-right: auto;
    font-size: 2.2rem;
    padding-top: var(--space-large);
    padding-bottom: var(--space-large);
    line-height: var(--line-height-xsmall);
    transition: background-color var(--transition-speed-fast) var(--bezier);
    &:hover {
      background-color: var(--color-accent-weak);
    }
  }
  &::before {
    background-image: url(https://gigaplus.makeshop.jp/matsuotegami/images/icon_print.svg);
    width: 16px;
    height: 16px;
    right: 25px;
    @include mq(tbl) {
      width: 24px;
      height: 24px;
    }
  }
}
.uniq {
  .tableWrapper {
    table {
      width: 100%;
      tr {
        &:nth-child(odd){
          background-color: var(--color-cell-bg);
        }
        &:nth-child(even){
          background-color: var(--color-surface);
        }
      }
      th, td {
        vertical-align: top;
        padding: var(--space-small) var(--space-medium);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        width: 100%;
        @include mq(tbl) {
          font-size: var(--font-size-small);
        }
      }
      th {
        vertical-align: middle;
        white-space: nowrap;
        width: auto;
      }
    }
  }
}
.uniq.cattitle {
  @include mq(tbl) {
    @include container(var(--padding-sp),wide);
    margin-top: 20px;
  }
  .inner {
    img {
      height: 372px;
      width: 100%;
      object-fit: cover;
      @include sp_pc;
      @include mq(tbl) {
        height: auto;
        aspect-ratio: 5 / 1;
      }
    }
    
  }
}
.uniq.itemList {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    h2 {
      font-size: var(--font-size-medium);
      letter-spacing: 1px;
      padding-bottom: var(--space-large);
      @include mq(tbl) {
        font-size: 2rem;
        padding-bottom: var(--space-xxlarge);
      }
    }
    .count {
      font-size: var(--font-size-small);
      line-height: var(--line-height-medium);
      padding-top: var(--space-small);
      @include mq(tbl) {
        font-size: var(--font-size-medium);
        padding-top: 0;
      }
      + .list {
        margin-top: var(--space-medium);
        @include mq(tbl) {
          margin-top: var(--space-large);
        }
      }
    }
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      grid-template-rows: subgrid;
      column-gap: 4%;
      @include mq(tbl) {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2.3%;
      }
      .unit {
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 4;
        column-gap: 0;
        padding-bottom: 60px;
        &.soldout {
          .image {
            position: relative;
            a {
              &::before {
                @include pd;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.4);
                z-index: 2;
              }
            }
          }
        }
        .image {
          a {
            display: block;
            @include hoverOp;
            position: relative;
            .soldout {
              display: inline-block;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              z-index: 2;
              font-size: var(--font-size-medium);
              color: var(--color-text-on-accent);
              white-space: nowrap;
              @include mq(tbl) {
                font-size: var(--font-size-large);
              }
            }
            img {
              aspect-ratio: 288 / 395;
              object-fit: cover;
              position: relative;
              z-index: 1;
            }
          }
        }
        h3 {
          font-size: var(--font-size-small);
          line-height: var(--line-height-small);
          margin-top: var(--space-large);
          @include mq(tbl) {
            margin-top: var(--space-xlarge);
            font-size: 1.5rem;
          }
          a {
            display: block;
            color: var(--color-text);
            @include hoverColor(var(--color-text-weak));
          }
        }
        .price {
          margin-top: var(--space-large);
          &:has(.sale) {
            display: flex;
            align-items: center;
            gap: 20px;
            .regular {
              text-decoration: line-through;
            }
          }
          .regular {
            font-size: var(--font-size-small);
            @include mq(tbl) {
              font-size: var(--font-size-medium);
            }
          }
          .sale {
            font-size: var(--font-size-small);
            color: var(--fg-danger);
            display: flex;
            align-items: center;
            @include mq(tbl) {
              font-size: var(--font-size-medium);
            }
            .off {
              font-size: var(--font-size-xxsmall);
              @include mq(tbl) {
                font-size: var(--font-size-xsmall);
              }
            }
          }
          .tax {
            font-size: var(--font-size-xxxsmall);
            display: none;
            @include mq(tbl) {
              font-size: var(--font-size-xxsmall);
            }
          }
        }
        .icon {
          display: flex;
          gap: 3px;
          flex-wrap: wrap;
          margin-top: 13px;
          li {
            img {
              height: 20px;
              width: auto;
              @include mq(tbl) {
                height: 20px;
              }
            }
          }
        }
        .button {
          margin-top: 14px;
          .p_button{
            padding: 9px 28px;
            @include mq(tbl) {
              padding: 14px 32px;
              &:hover {
                &::after, &::before {
                  background-color: currentColor;
                }
              }
            }
            &::before {
              background-image: none;
              width: 11px;
              height: 1px;
              background-color: var(--color-brand);
              right: 12px;
              @include mq(tbl) {
                right: 15px;
              }
            }
            &::after {
              @include pd;
              width: 1px;
              height: 11px;
              background-color: var(--color-brand);
              top: 50%;
              right: 17px;
              transform: translateY(-50%);
              @include mq(tbl) {
                right: 20px;
              }
            }
          }
        }
      }
    }
  }
  &.small {
    margin-top: var(--margin-top-medium);
    border-top: 1px solid var(--border-sub);
    padding-top: var(--margin-top-medium);
    @include mq(tbl) {
      margin-top: var(--margin-top-large);
    }
    + .uniq.itemList.small {
      margin-top: var(--margin-top-xsmall);
      @include mq(tbl) {
        margin-top: var(--margin-top-small);
      }
    }
    .inner {
      @include mq(tbl) {
        @include container(var(--padding-sp),wide);
      }
      .list {
        @include mq(tbl) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          column-gap: 1.8%;
        }
      }
    }
  }
}
.uniq.itemDetail {
  margin-top: 20px;
  padding-bottom: var(--space-large);
  @include mq(tbl) {
    margin-top: 60px;
    padding-bottom: var(--space-xxxlarge);
  }
  .inner {
    @include mq(tb) {
      display: grid;
      gap: 6%;
      grid-template:
        "image text" auto
        / 1fr 37%;
    }
    .image {
      overflow: hidden;
      @include mq(tb) {
        grid-area: image;
        display: grid;
        gap: 2%;
        grid-template: 
          "thumb main" auto
          / 13% 1fr;
      }
      .image-main {
        @include mq(tb) {
          grid-area: main;
        }
        .swiper-main-images {
          width: 100%;
          .swiper-slide {
            width: 100% !important;
            max-width: 100%;
            height: auto !important;
            aspect-ratio: 375 / 513;
          }
          .swiper-wrapper {
            img {
              display: block;
              margin-inline: auto;
              aspect-ratio: 375 / 513;
              object-fit: cover;
            }
          }
          .swiper-pagination {
            position: relative;
            margin-top: 25px;
            @include mq(tb) {
              display: none;
            }
            .swiper-pagination-bullet {
              width: 14px;
              height: 14px;
              display: inline flex;
              align-items: center;
              justify-content: center;
              background-color: unset;
              margin: unset;
              &::before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                background-color: var(--fg-sub);
                border-radius: 50%;
              }
              &.swiper-pagination-bullet-active {
                &::before {
                  background-color: var(--fg-default);
                }
              }
            }
          }
        }

      }
      .image-thumb {
        @include mq(tb) {
          grid-area: thumb;
        }
        .choice-btn {
          display: none;
          width: 100%;
          @include mq(tb) {
            display: block;
          }
          .swiper-wrapper {
            display: grid;
            grid-auto-flow: row;
            gap: 13px;
            .swiper-slide {
              cursor: pointer;
              background: #333;
              position: relative;
              width: auto !important;
              height: auto !important;
              aspect-ratio: 375 / 513;
              &.swiper-slide-thumb-active::before {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                border: 1px solid #333;
              }
              img {
                object-fit: cover;
                aspect-ratio: 375 / 513;
              }
            }
          }
        }

      }
    }
    
    .text {
      position: relative;
      margin-top: 55px;
      padding-inline: var(--padding-sp);
      @include mq(tb) {
        grid-area: text;
        margin-top: unset;
        padding-inline: 0 var(--padding-sp) ;
      }
      .cat {
        font-size: var(--font-size-xsmall);
        margin-bottom: var(--space-xlarge);
      }
      h1 {
        line-height: var(--line-height-small);
        position: relative;
        font-size: 2rem;
      }
      .icon {
        display: flex;
        gap: 3px;
        flex-wrap: wrap;
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 20px;
        }
        li {
          img {
            height: 21px;
            width: auto;
            @include mq(tbl) {
              height: 25px;
            }
          }
        }
      }
      .pricebox {
        margin-top: var(--space-xlarge);
        font-size: var(--font-size-small);
        display: flex;
        align-items: center;
        @include mq(tbl) {
          margin-top: 30px;
        }
        .teiki {
          color: var(--fg-third);
          padding-right: 10px;
          white-space: nowrap;
        }
        .price {
          display: flex;
          align-items: center;
          .en {
            font-size: 2rem;
            font-weight: 400;
          }
          .tax {
            font-size: var(--font-size-xsmall);
            margin-left: var(--space-xsmall);
          }
          .point {
            margin-left: var(--space-large);
            font-size: var(--font-size-xxsmall);
            display: block;
            border: 1px solid var(--border-third);
            padding: 2px var(--space-large);
            color: var(--fg-third);
            border-radius: 2px;
            strong {
              font-weight: inherit;
            }
          }
        }
        .favorite {
          margin-left: auto;
          padding-top: 4px;
          @include hoverOp;
        }
      }
      .quantity {
        margin-top: var(--space-xxlarge);
        display: flex;
        align-items: center;
        gap: var(--space-small);
        display: none;
        @include mq(tbl) {
          margin-top: 35px;.tab-content {
            display: none;
            opacity: 0;
            transition: opacity 0.3s ease;
        }
        .tab-content.active {
            display: block;
            opacity: 1;
        }
        .tab-button {
            cursor: pointer;
            padding: 10px;
            border: 1px solid #ccc;
            background-color: #f1f1f1;
            margin-right: 5px;
            transition: background-color 0.3s ease;
        }
        .tab-button.active {
            background-color: #ddd;
        }
          justify-content: flex-end;
        }
        dt {
          min-width: 5em;
          font-size: var(--font-size-small);
          @include mq(tbl) {
            font-size: var(--font-size-xsmall);
            min-width: 0;
          }
        }
        dd {
          input {
            font: inherit;
            appearance: none;
            font-size: var(--font-size-small);
            color: currentColor;
            padding: var(--space-small);
            border: 1px solid #BBC3CD;
            width: 80px;
            text-align: center;
            outline: none;
          }
        }
      }
      .options {
        margin-top: 30px;
        .pulldown {
          padding-bottom: 30px;
          display: grid;
          grid-template-columns: auto 1fr;
          .makeshop-option-wrap {
            gap: var(--space-small);
            border-bottom: 1px solid var(--border-third);
            display: grid;
            grid-template-columns: subgrid;
            grid-column: span 2;
            align-items: center;
            gap: 25px;
            padding-block: var(--space-medium);
            &:first-of-type {
              margin-top: unset;
            }
            .makeshop-option-label {
              padding-left: 8px;
              font-size: var(--font-size-xsmall);
              white-space: nowrap;
            }
            .makeshop-option-select-wrap {
              .makeshop-option-select {
                font: inherit;
                font-size: var(--font-size-medium);
                color: currentColor;
                padding: var(--space-xsmall);
                line-height: 1.2;
                border: transparent;
                width: 100%;
              }
            }
          }

        }
        .sku {
          width: 100%;
          display: grid;
          grid-template-columns: auto 1fr auto auto auto;
          .header, .unit {
            display: grid;
            grid-template-columns: subgrid;
            grid-column: 1 / -1;
            align-items: center;
            padding-block: 12px;
            border-bottom: 1px solid var(--border-sub);
            line-height: var(--line-height-medium);
            .sku-image {
              padding-right: 10px;
              @include mq(tbl) {
                padding-right: 30px;
              }
              img {
                max-width: 40px;
                @include mq(tbl) {
                  max-width: 55px;
                }
              }
            }
            .sku-option {
              padding-right: 8px;
              font-size: 1.3rem;
              @include mq(tbl) {
                padding-right: 15px;
              }
              &.size {
                text-align: center;
              }
            }
            .sku-button {
              padding-right: 12px;
              @include mq(tbl) {
                padding-right: 18px;
              }
              .add-catalog-button, .remind, .add-catalog-button {
                margin-top: unset;
                a {
                  padding-block: 10px;
                  min-width: 120px;
                  @include mq(tbl) {
                    padding-block: 12px;
                    min-width: 140px;
                  }
                }
              }
            }
            .sku-fav {
              a {
                img {
                  width: 14px;
                  @include mq(tbl) {
                    width: 20px;
                  }
                }
              }
            }
          }
          .header {
            padding-block: 8px;
            .sku-option {
              font-size: var(--font-size-xxsmall);
            }
          }
        }
      }
      .stock {
        margin-top: var(--space-large);
        font-size: var(--font-size-xsmall);
      }
      .soldout {
        margin-top: var(--space-small);
        color: var(--color-text-on-accent) !important;
        text-align: center;
        display: block;
        background-color: var(--bg-third);
        padding-top: 17px;
        padding-bottom: 19px;
        font-size: var(--font-size-xsmall);
        @include mq(tbl) {
          padding-left: var(--space-large);
          padding-right: var(--space-large);
          margin-top: var(--space-medium);
        }
      }
      .add-catalog-button, .remind {
        margin-top: var(--space-small);
        @include mq {
          margin-top: var(--space-medium);
        }
        a {
          display: block;
          min-width: 0;
          text-align: center;
          font-size: var(--font-size-xsmall);
          background-color: var(--fg-default);
          padding-top: 17px;
          padding-bottom: 19px;
          transition: background-color var(--transition-speed-fast) var(--bezier);
          @include mq(tbl) {
            padding-left: var(--space-large);
            padding-right: var(--space-large);
          }
          &::before {
            background-image: none;
            content: none;
          }
        }
      }
      .add-catalog-button {
        a {
          color: var(--fg-inverse);
          @include mq(tbl) {
            &:hover {
              background-color: var(--fg-third);
            }
          }
        }
      }
      .remind {
        a {
          border: 1px solid var(--border-third);
          background: #FFF;
          transition: border var(--transition-speed-fast) var(--bezier);
          @include mq(tbl) {
            &:hover {
              border-color: var(--border-default);
            }
          }
        }
      }
      .free-shipping {
        margin-top: var(--space-xsmall);
        display: none;
        h3 {
          font-size: var(--font-size-small);
          font-weight: var(--font-weight-bold);
          color: var(--color-third);
          display: inline-block;
          padding-bottom: var(--space-xxsmall);
          border-bottom: 1px solid var(--color-third);
        }
        .message {
          text-align: right;
          margin-top: var(--space-xsmall);
          font-size: var(--font-size-xsmall);
          em {
            font-weight: var(--font-weight-bold);
            color: var(--color-third);
          }
        }
      }
      .tab {
        margin-top: 75px;
        .tab-buttons {
          display: flex;
          font-size: var(--font-size-small);
          @include mq(tbl) {
            font-size: 1.5rem;
          }
          .tab-button {
            flex: 1;
            padding-bottom: var(--space-xlarge);
            cursor: pointer;
            background-color: transparent;
            appearance: none;
            transition: background-color 0.1s var(--bezier);
            border-bottom: 2px solid var(--border-sub);
            @include mq(tbl) {
              padding-bottom: var(--space-large);
            }
            &.active {
              border-color: var(--border-default);
            }
          }
        }
        .tab-contents {
          padding-top: var(--space-xxlarge);
          .tab-content {
            display: none;
            opacity: 0;
            transition: opacity 0.1s var(--bezier);
            font-size: 1.5rem;
            line-height: var(--line-height-large);
            &.active {
              display: block;
              opacity: 1;
            }
            dt {
              font-weight: 700;
              color: var(--fg-sub);
              margin-top: 1em;
              &:first-of-type {
                margin-top: 0;
              }
            }
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
.uniq.pageTitle {
  background-color: var(--color-column-bg);
  .inner {
    @include container;
    h1 {
      text-align: center;
      font-family: var(--font-family-heading);
      padding-top: var(--space-xlarge);
      padding-bottom: var(--space-xlarge);
      font-size: 2.2rem;
      @include mq(tbl) {
        font-size: var(--font-size-xlarge);
        padding-top: var(--space-xxlarge);
        padding-bottom: var(--space-xxlarge);
      }
    }
    .logo {
      display: none;
    }
  }
}
.uniq.printList {
  margin-top: var(--space-large);
  @include mq(tbl) {
    margin-top: var(--space-xlarge);
  }
  .inner {
    @include container;
    .unit {
      margin-top: var(--space-medium);
      border: 1px solid var(--color-border-strong);
      border-radius: var(--border-radius);
      padding: var(--space-large) var(--space-medium);
      position: relative;
      @include mq(tb) {
        padding: var(--space-large) 80px var(--space-large) var(--space-medium);
      }
      @include mq(tbl) {
        padding: var(--space-xlarge) 124px var(--space-xxlarge) var(--space-xxxlarge);
      }
      &:first-of-type {
        .position-up {
          pointer-events: none !important;
        }
        .position-up img {
          opacity: 0.1 !important;
        }
      }
      &:last-of-type {
        .position-down {
          pointer-events: none !important;
        }
        .position-down img {
          opacity: 0.1 !important;
        }
      }
      h2 {
        line-height: var(--line-height-small);
        position: relative;
        padding-bottom: var(--space-small);
        font-size: 2.0rem;
        @include mq(tbl) {
          font-size: var(--font-size-xlarge);
          padding-bottom: var(--space-medium);
        }
        &::before, &::after {
          @include pd;
          width: 100%;
          height: 2px;
          left: 0;
          bottom: 0;
          background-color: var(--color-brand);
        }
        &::after {
          background-color: var(--color-accent);
          width: 40px;
        }
        a {
          color: var(--color-text);
        }
      }
      .detail {
        margin-top: var(--space-large);
        @include mq(tb) {
          margin-top: var(--space-xlarge);
          display: flex;
          justify-content: space-between;
        }
        .image {
          text-align: center;
          @include mq(tb) {
            width: 31.6%;
          }
          img {
            border: 1px solid var(--color-border);
            max-width: 90%;
            max-height: 280px;
            @include mq(tb) {
              max-width: 100%;
            }
          }
        }
        .desc {
          margin-top: var(--space-large);
          @include mq(tb) {
            width: 63.4%;
            margin-top: unset;
          }
          .tableWrapper table th {
            padding-right: var(--space-xlarge);
            vertical-align: top;
          }
          .amountWrapper {
            display: flex;
            justify-content: flex-start;
            .amount-sub, .amount-add {
              all: unset;
              outline: revert;
              border: 1px solid #AAABAA;
              display: flex;
              cursor: pointer;
              align-items: center;
              background-color: var(--color-cell-bg);
              padding: 0 var(--space-xxxsmall);
              &:hover {
                background-color: var(--color-surface);
              }
              &:active {
                background-color: var(--color-surface-weaker);
              }
            }
            .amount-sub {
              border-right: 0;
              border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
            }
            .amount-add {
              border-left: 0;
              border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
            }
            .amount {
              border: 1px solid #AAABAA;
              padding: 0 var(--space-xxsmall);
              font-size: 1.6rem;
              line-height: var(--line-height-small);
              width: 60px;
              text-align: center;
            }
          }
        }
      }
      .utility {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        @include mq(tb) {
          display: block;
          position: absolute;
          top: var(--space-xlarge);
          right: var(--space-medium);
        }
        @include mq(tbl) {
          right: var(--space-xlarge);
        }
        .position {
          display: flex;
          flex-direction: row;
          @include mq(tb) {
            display: block;
            border: 2px solid var(--color-brand);
            border-radius: var(--border-radius-small);
          }
        }
        .position-up, .position-down, .delete {
          all: unset;
          outline: revert;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          background-color: #5d5d5d;
          width: 36px;
          height: 40px;
          transition: background-color var(--transition-speed-fast) var(--bezier);
          border: 2px solid var(--color-brand);
          border-radius: var(--border-radius-small);
          &:hover {
            background-color: var(--color-brand-weak);
          }
          @include mq(tb) {
            border: none;
            border-radius: 0;
          }
        }
        .position-up {
          margin-right: var(--space-medium);
          @include mq(tb) {
            margin-right: 0;
            border-bottom: 1px solid var(--color-brand);
          }
        }
        .delete {
          border: 2px solid var(--color-brand);
          border-radius: var(--border-radius-small);
          @include mq(tb) {
            margin-top: 16px;
          }
        }
      }
    }
  }
  .noitem {
    display: none;
    @include container;
  }
}
.uniq.noItem {
  display: none;
  .inner {
    @include container;
    min-height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: var(--space-large);
    @include mq(tbl) {
      min-height: 400px;
    }
    p {
      text-align: center;
      font-size: var(--font-size-large);
    }
    button {
      margin-top: var(--space-large);
      @extend .p_button;
      padding-right: var(--space-medium);
      @include mq(tbl) {
        margin-top: var(--space-xxlarge);
      }
      &::before {
        transform: translateY(-50%) rotate(180deg);
        right: auto;
        left: 12px;
      }
    }
  }
  
}
.uniq.printButton {
  margin-top: var(--margin-top-large);
  padding-bottom: 20px;
  @include mq(tbl) {
    margin-top: var(--margin-top-xlarge);
    padding-bottom: var(--space-xxxlarge);
  }
  .inner {
    @include container;
    .print {
      @include print-button();
    }
  }
}
.uniq.floatButtons,
.uniq.floatButton {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  background-color: rgba(75,75,75,.7);
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
  transition: transform var(--transition-speed) var(--bezier);
  transform: translateY(100%);
  &.is-show {
    transform: translateY(0);
  }
  @include mq(tbl) {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large);
  }
  .inner {
    @include container;
    .buttons {
      display: flex;
      flex-direction: row-reverse;
      gap: var(--space-xsmall);
      @include mq(tbl) {
        max-width: 825px;
        margin-left: auto;
        margin-right: auto;
      }
      .print {
        @include print-button();
        padding-left: var(--space-medium);
        padding-right: var(--space-medium);
        flex: 1;
        @include mq(tbl) {
          padding-top: var(--space-medium);
          padding-bottom: var(--space-medium);
          font-size: 1.8rem;
        }
        span {
          display: none;
          @include mq(tbl) {
            display: inline;
          }
        }
      }
      .delete {
        @include print-button();
        background-color: var(--color-brand);
        padding-left: var(--space-medium);
        padding-right: var(--space-medium);
        width: 35%;
        @include mq(tbl) {
          font-size: var(--font-size-large);
          padding-top: var(--space-medium);
          padding-bottom: var(--space-medium);
          width: 40%;
          &:hover {
            background-color: #5c5c5c;
          }
        }
        &::before {
          content: none;
        }
      }
    }
    .button {
      @include mq(tbl) {
        max-width: 825px;
        margin-left: auto;
        margin-right: auto;
      }
      .download {
        @include print-button();
        padding-left: var(--space-medium);
        padding-right: var(--space-medium);
        flex: 1;
        &::before {
          content: none;
        }
        @include mq(tbl) {
          padding-top: var(--space-medium);
          padding-bottom: 22px;
          font-size: 1.8rem;
        }
        i {
          vertical-align: 2px;
          margin-left: var(--space-xxsmall);
          @include mq(tbl) {
            vertical-align: 3px;
            margin-left: var(--space-xsmall);
          }
          img {
            width: 18px;
            @include mq(tbl) {
              width: 22px;
            }
          }
        }
      }
    }
    p {
      margin-top: var(--space-xxsmall);
      color: var(--color-text-on-accent);
      font-size: var(--font-size-xxsmall);
      line-height: var(--line-height-xsmall);
      @include mq(tbl) {
        text-align: center;
        font-size: var(--font-size-small);
        margin-top: var(--space-xsmall);
      }
    }
  }
}
.uniq.floatButton {
  display: none;
  @include mq(tbl) {
    display: block;
  }
}
.uniq.pagetitle {
  margin-top: var(--space-large);
  @include mq(tbl) {
    margin-top: var(--space-xlarge);
  }
  .inner {
    @include container;
    h1 {
      font-size: 2.2rem;
      @include mq(tbl) {
        font-size: 3rem;
      }
    }
  }
}
.uniq.noResult {
  margin-top: var(--margin-top-xlarge);
  .inner {
    p {
      text-align: center;
      font-size: var(--font-size-small);
      line-height: var(--line-height-medium);
      @include mq(tbl) {
        font-size: var(--font-size-medium);
      }
    }
    .component.type_button {
      margin-top: var(--margin-top-xlarge);
      @include mq(tbl) {
        margin-top: 120px;
      }
      .inner {
        ul {
          border-top: 1px solid var(--border-sub);
          padding-top: var(--space-xxlarge);
          display: block;
          li {
            margin-left: 0;
            margin-right: 0;
            .p_button {
              @include mq(tbl) {
                width: 280px;
              }
            }
          }

        }
      }

    }
  }
}
.uniq.catbanner {
  margin-top: 25px;
  text-align: right;
  @include container;
  @include mq(tbl) {
    margin-top: 17px;
  }
  img {
    width: 180px;
    @include mq(tbl) {
      width: 279px;
    }
  }
  + .component.type_heading {
    margin-top: 30px;
    @include mq(tbl) {
      margin-top: unset;
    }
  }
}
.uniq.sort {
  margin-top: 20px;
  @include container;
  @include mq(tbl) {
    margin-top: 20px;
    display: flex;
  }
  .total {
    text-align: left;
    font-size: var(--font-size-xsmall);
    padding-bottom: var(--space-small);
    @include mq(tbl) {
      font-size: var(--font-size-medium);
      padding-bottom: 0;
    }
    strong {
      font-size: var(--font-size-medium);
      margin: 0 var(--space-xxsmall);
      @include mq(tbl) {
        font-size: 2rem;
      }
    }
  }
  dl {
    display: flex;
    font-size: var(--font-size-xxsmall);
    @include mq(tbl) {
      font-size: 1.3rem;
      justify-content: flex-end;
      align-self: flex-end;
      margin-left: auto;
    }
    dt {
      font-weight: var(--font-weight-bold);
    }
    dd {
      margin-left: 1em;
      font-weight: var(--font-weight-normal);
      &:first-of-type {
        margin-left: 0;
        @include mq(tbl) {
          margin-left: 1em;
        }
      }
      a {
        @include hoverColor(var(--color-text-weak));
      }
    }
  }
}
.component.type_heading.recommend {
  margin-top: var(--margin-top-xlarge);
}

.component.type_faq.uniq {
  .inner {
    @include container;
    .unit {
      @include mq(tbl) {
        border-top: none;
      }
      &:last-of-type {
        @include mq(tbl) {
          border-bottom: none;
        }
      }
      &.order {
        display: none;
      }
      .q {
        cursor: pointer;
        display: block grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        margin-top: var(--space-xxxlarge);
        font-size: var(--font-size-medium);
        border-bottom: 1px solid var(--border-third);
        padding-bottom: var(--space-xlarge);
        @include hoverOp(.7);
        padding-left: unset;
        &::after {
          content: '';
          background-image: var(--arrow-bottom);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 13px;
          height: 1lh;
          transition: rotate var(--transition-speed-fast) var(--bezier);
        }
        &.active {
          &::after {
            rotate: z 180deg;
          }
        }
        &:before {
          content: none;
        }
      }
      .a {
        padding: var(--space-large) var(--space-medium);
        @include mq(tbl) {
          padding: var(--space-xlarge) var(--space-large);
        }
        &:before {
          content: none;
        }
        p {
          img {
            &.ss {
              margin: 0 !important;
              max-width: 375px;
              width: 100%;

            }
          }
        }
      }
    }
  }
}
.uniq.lineup {
  background-color: #EAE7E0;
  padding: 60px 0 110px;
  margin-top: 60px;
  @include mq {
    padding: 125px 0;
    margin-top: 130px;
  }
  .component.type_heading.recommend {
    margin-top: unset;
    text-align: center;
    @include mq {
      text-align: left;
    }
    .inner {
      position: relative;
      .obj {
        margin-bottom: 18px;
        display: inline-block;
        @include mq {
          position: absolute;
          right: calc(50% - (var(--width-container) / 2) + 15px);
          top: -64px;
          z-index: 2;
        }
        img {
          width: 107px;
          @include mq {
            width: 172px;
          }
        }
      }
    }
  }
  .uniq.itemList.small {
    margin-top: 55px;
    margin-bottom: 0;
    .inner .list {
      padding-bottom: 15px;
      .unit {
        padding-bottom: 45px;
      }
    }
  }
}
#banner {
  position: fixed;
  left: 12px;
  bottom: 3px;
  z-index: 99;
  transition: opacity var(--transition-speed) var(--bezier);
  &.is-hide {
    opacity: 0;
    pointer-events: none;
  }
  @include mq {
    display: none;
  }
  #banner-close {
    position: absolute;
    top: 11px;
    right: 10px;
    width: 31px;
    filter:drop-shadow(3px 3px 4px rgba(91,43,11,0.25));
    cursor: pointer;
  }
  a {
    display: block;
    img {
      width: 163px;
    }
  }
}
#wrapper:is(:has(> .uniq.lineup:last-child)) + footer {
  margin-top: unset;
}

.uniq.slideitem {
  margin-top: var(--margin-top-medium);
  border-top: 1px solid var(--border-sub);
  padding-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
    margin-inline: calc(50% - 50vw);
  }
  .inner {
    h2 {
      font-size: var(--font-size-medium);
      letter-spacing: 1px;
      padding-bottom: var(--space-large);
      padding-inline: var(--padding-sp);
      @include mq(tbl) {
        font-size: 2rem;
        padding-inline: 50px;
        padding-bottom: var(--space-xxlarge);
      }
    }
    .list {
      @include mq(tbl) {
        padding-left: 40px;
      }
      .unit {
        margin-left: 26px;
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 2;
        column-gap: 0;
        @include mq(tbl) {
          margin-left: 12px;
        }
        .image {
          img {
            aspect-ratio: 288 / 395;
            object-fit: cover;
            position: relative;
            z-index: 1;
            width: 187px;
            // transition: scale var(--transition-speed) var(--bezier);
            @include mq(tbl) {
              width: 260px;
            }
            &:hover {
              // scale: 1.05;
            }
          }
        }
        h3 {
          font-size: 1.5rem;
          line-height: var(--line-height-small);
          margin-top: var(--space-small);
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: 110px;
    left: 18px;
    z-index: 10;
    cursor: pointer;
    @include hoverOp(.7);
    @include mq(tbl) {
      left: 40px;
      top: 155px;
    }
    img {
      width:38px;
      transform: rotate(180deg);
      filter: drop-shadow(0px -4px 4px rgba(0,0,0,0.25));
    }
    &.slick-next {
      left: auto;
      right: 18px;
      @include mq(tbl) {
        right: 40px;
      }
      img {
        transform: rotate(0deg);
        filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.25));
      }
    }
    &.slick-disabled {
      opacity: 0;
    }
  }
}
.uniq.staticpagetitle {
  margin-top: var(--margin-top-large);
  @include container;
  @include mq(tbl) {
    margin-top: 97px;
  }
  .inner {
    h1 {
      font-size: var(--font-size-medium);
    }
    .date {
      font-size: var(--font-size-small);
      color: var(--fg-third);

    }
  }
  &:has(.date){
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid var(--border-third);
      padding-bottom: var(--space-xlarge);
    }
  }
}