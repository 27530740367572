@charset "UTF-8";

@import "mixins";

.navigation.localnav_sub {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .navigation.archivenav + & {
    margin-top: var(--margin-top-small);
    @include mq(tbl) {
      margin-top: var(--margin-top-medium);
    }
  }
  .inner {
    @include container;
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        margin-right: var(--space-small);
        margin-bottom: var(--space-small);
        white-space: nowrap;
        &.active,&.current_page_item {
          a {
            background-color: var(--color-brand);
            color: var(--color-text-on-accent);
            font-weight: var(--font-weight-bold);
          }
        }
        a {
          display: inline-block;
          background-color: var(--color-surface-weaker);
          color: var(--color-text);
          font-size: var(--font-size-xsmall);
          padding: var(--space-xsmall) var(--space-small);
          text-decoration: none;
          @include mq(tbl) {
            @include hoverOp(.5);
            font-size: var(--font-size-small);
            padding: var(--space-xsmall) var(--space-medium);
          }
        }
      }
    }
  }
}
