@charset "UTF-8";

@import "mixins";

.component.type_catchcopy {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.withbg {
    padding-bottom: var(--padding-bottom-catchcopy);
    background-repeat: no-repeat;
    background-position: right bottom;
    @include mq(tbl) {
      padding-bottom: var(--padding-bottom-catchcopy-pc);
    }
    + * {
      margin-top: unset;
      @include mq(tbl) {
        margin-top: unset;
      }
    }
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: grid;
      grid-template: 
        "heading text" auto
        "copy text" auto
        "button text" auto
        / 1fr 47%;
        grid-column-gap: 30px;
    }
    .heading {
      @include mq(tb) {
        grid-area: heading;
      }
      h3 {
        font-size: var(--font-size-xlarge);
        line-height: var(--line-height-medium);
      }
    }
    .copy {
      @include mq(tb) {
        grid-area: copy;
      }
      h4 {
        margin-top: var(--space-large);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          font-size: 1.3rem;
        }
      }
      .button {
        display: none;
        margin-top: var(--space-xxxlarge);
        @include mq(tb) {
          display: block;
          grid-area: button;
        }
        a {
          text-align: center;
        }
      }
    }
    .text {
      margin-top: var(--space-xxlarge);
      @include mq(tb) {
        margin-top: unset;
        grid-area: text;
      }
    }
    .button {
      margin-top: var(--space-large);
      @include mq(tb) {
        display: none;
      }
      a {
        text-align: center;
      }
    }
  }
}
