@charset "UTF-8";

@import "mixins";

.component.type_faq {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    .unit {
      border-top: 1px solid var(--border-third);
      &:first-of-type {
        border: none;
      }
      &:last-of-type {
        border-bottom: 1px solid var(--border-third);
      }
      .q {
        padding-bottom: var(--space-large);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        position: relative;
        margin-top: var(--space-xlarge);
        display: block grid;
        grid-template-columns: auto 1fr auto;
        gap: 10px;
        @include mq(tbl) {
          padding-bottom: var(--space-xlarge);
          margin-top: var(--space-xxlarge);
          font-size: var(--font-size-medium);
          cursor: pointer;
          &:hover {
            color: var(--color-brand);
          }
        }
        &.active {
          &::after {
            rotate: z 180deg;
          }
        }
        &:after {
          content: '';
          background-image: var(--arrow-bottom);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 13px;
          height: 1lh;
          transition: rotate var(--transition-speed-fast) var(--bezier);
        }
        &:before {
          content: 'Q.';
          font-size: var(--font-size-large);
          line-height: 1lh;
          font-family: var(--font-family-en);
          font-weight: var(--font-weight-normal);
          @include mq(tbl) {
            font-size: var(--font-size-large);
          }
        }
      }
      .a {
        padding: var(--space-xlarge) 0 var(--space-xlarge) var(--space-xlarge);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        position: relative;
        display: none;
        @include mq(tbl) {
          padding: var(--space-xlarge) 0 var(--space-xxlarge) var(--space-xxlarge);
          font-size: var(--font-size-medium);
        }
        &:before {
          @include pd;
          content: 'A.';
          left: var(--left-faq-a);
          font-size: var(--font-size-large);
          line-height: 1lh;
          font-family: var(--font-family-en);
          font-weight: var(--font-weight-normal);
          @include mq(tbl) {
            left: var(--left-faq-a-pc);
          }
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        img {
          display: block;
          margin: 0 auto var(--space-small) !important;
          &.mt-image-right {
            float: none !important;
            @include mq(tbl) {
              float: right !important;
              margin: 0 0 var(--space-large) var(--space-large) !important;
            }
          }
          &.mt-image-left {
            float: none !important;
            @include mq(tbl) {
              float: left !important;
              margin: 0 var(--space-large) var(--space-large) 0 !important;
            }
          }
        }
        p {
          padding-bottom: var(--space-large);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
