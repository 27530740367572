@charset "UTF-8";

@import "mixins";

.component.type_panel {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.set2 {
    .inner {
      @include grid(2,var(--gap-2col-col),var(--gap-2col-row));
      @include mq(tbl) {
        @include grid(2,var(--gap-2col-col-pc),var(--gap-2col-row-pc));
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(1,var(--gap-2col-col),var(--gap-2col-row));
      @include mq(tbl) {
        @include grid(3,var(--gap-3col-col-pc),var(--gap-3col-row-pc));
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,var(--gap-2col-col),var(--gap-2col-row));
      @include mq(tbl) {
        @include grid(4,var(--gap-4col-col-pc),var(--gap-4col-row-pc));
      }
      .unit {
        .button {
          a {
            min-width: 100%;

          }
        }
      }
    }
  }
  .inner {
    @include container;
    .unit {
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 4;
      gap: 0;
      @include mq(tb) {
        max-width: var(--width-panel-single-pc);
        flex: 1;
        position: relative;
      }
      .photo {
        @include mq(tb) {
          display: flex;
          align-items: center;
        }
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
        }
      }
      .title {
        margin-top: var(--space-xlarge);
        @include mq(tbl) {
          margin-top: var(--space-xxlarge);
        }
        h3 {
          font-weight: var(--font-weight-bold);
          font-size: 1.3rem;
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            font-size: 1.5rem;
          }
          a {
            color: var(--color-text);
            @include mq(tbl) {
              @include hoverOp;
            }
          }
        }
      }
      .desc {
        margin-top: var(--space-medium);
        font-size: 1.3rem;
        line-height: var(--line-height-large);
        text-decoration: none;
        @include mq(tbl) {
          margin-top: var(--space-large);
          font-size: 1.5rem;
        }
        a {
          text-decoration: none;
          @include mq(tbl) {
            @include hoverOp;
          }
        }
      }
      .date {
        text-decoration: none;
        margin-top: var(--space-small);
      }
      .button {
        margin-top: var(--space-large);
        text-align: right;
        @include mq(tbl) {
          margin-top: var(--space-xxlarge);
        }
        a {
          text-align: center;
        }
      }
    }
  }
}
