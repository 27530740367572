@charset "UTF-8";

@import "mixins";

.top_mv {
  position: relative;
  video {
    height: 100dvh;
    max-height: 800px;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    @include mq {
      height: 800px;
    }
  }
  #video-controls {
    position: absolute;
    z-index: 2;
    right: 17px;
    top: 75px;
    @include mq {
      right: unset;
      top: unset;
      bottom: 30px;
      left: 30px;
    }
    li {
      #mute {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 26px;
        height: 17px;
        cursor: pointer;
        transition: transform var(--transition-speed-fast) var(--bezier);
        @include mq {
          width: 30px;
          height: 20px;
        }
        &.sound-off {
          background-image:url(https://gigaplus.makeshop.jp/matsuotegami/images/icon_sound_on.svg);
        }
        &.sound-on {
          background-image:url(https://gigaplus.makeshop.jp/matsuotegami/images/icon_sound_off.svg);
        }
        @media (any-hover: hover) {
          &:hover {
            scale: 1.1;
          }
        }
        span {
          display: none;
        }
      }
    }
  }
  .inner {
    height: 100dvh;
    max-height: 800px;
    padding: 30px 35px;
    position: relative;
    pointer-events: none;
    @include mq {
      height: 800px;
      padding-left: calc(50% - 550px);
      padding-right: calc(50% - 550px);
    }
    h1 {
      position: absolute;
      left: 30px;
      bottom: 35px;
      @include mq {
        left: pc(87px);
        bottom: pc(102px);
      }
      img {
        width: vw(117px);
        max-width: 180px;
        @include mq {
          width: pc(173px);
          max-width: none;
        }
      }
    }
    .logo {
      display: none;
      @include mq {
        display: block;
        position: absolute;
        right: pc(90px);
        bottom: pc(52px);
        img {
          width: pc(392px);
        }
      }
    }
  }
}
.top_section01 {
  background-color: rgba(33,59,93,.6);
  background-image: url(https://gigaplus.makeshop.jp/matsuotegami/images/top_section01_bg.jpg);
  background-position: center bottom;
  background-size: cover;
  overflow: hidden;
  padding: 80px 0 60px;
  position: relative;
  @include mq {
    padding: 110px 0 120px;
  }
  .obj {
    position: absolute;
    left: -30px;
    bottom: -10px;
    width: 147px;
    opacity: .3;
    z-index: 1;
    @include mq {
      left: auto;
      right: -60px;
      bottom: -60px;
      width: 448px;
    }
  }
}
#topbanner {
  .slick-slide {
    a {
      @include mq(tbl) {
        display: block;
        padding: 0 15px;
      }
      img {
        @include mq(tbl) {
          width: 690px;
        }
      }
    }
  }
  .slick-dots {
    @include slickdots(6px,6px);
    margin-top: 14px;
    @include mq {
      @include slickdots(12px,12px);
      margin-top: 25px;
    }
    
    li {
      button {
        border-radius: var(--border-radius-circle);
        background-color: transparent;
        border: 1px solid var(--color-surface);
      }
      &.slick-active {
        button {
          background-color: var(--color-surface);
        }
      }
    }
  }
}
.top_recommend {
  margin-top: 80px;
  position: relative;
  z-index: 2;
  @include mq {
    margin-top: 140px;
  }
  .inner {
    @include container;
    @include mq {
      display: grid;
      grid-template: 
        "list list title" auto
        / 1fr 1fr 1fr;
      gap: 40px;
    }
    > .title {
      display: flex;
      flex-direction: row-reverse;
      gap: 22px;
      @include mq {
        grid-area: title;
        gap: 28px;
      }
      h1 {
        img {
          width: 45px;
          @include mq {
            width: 68px;
          }
        }
      }
      p {
        writing-mode: vertical-rl;
        font-size: var(--font-size-small);
        color: var(--color-text-on-accent);
        line-height: var(--line-height-medium);
        letter-spacing: -3px;
        @include mq {
          font-size: var(--font-size-large);
          letter-spacing: -5px;
        }
      }
    }
    .list {
      margin-top: 75px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      color: var(--color-text-on-accent);
      @include mq {
        grid-area: list;
        column-gap: 40px;
      }
      .unit {
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 4;
        column-gap: 0;
        padding-bottom: 40px;
        @include mq {
          padding-bottom: 80px;
        }
        &.soldout {
          .image {
            position: relative;
            a {
              &::before {
                @include pd;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.3);
                z-index: 2;
              }
            }
          }
        }
        .image {
          a {
            display: block;
            @include hoverOp;
            position: relative;
            .soldout {
              display: inline-block;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              z-index: 2;
              font-size: var(--font-size-medium);
              color: var(--color-text-on-accent);
              font-weight: var(--font-weight-bold);
              white-space: nowrap;
              @include mq(tbl) {
                font-size: var(--font-size-large);
              }
            }
            img {
              aspect-ratio: 1 / 1;
              object-fit: cover;
              position: relative;
              z-index: 1;
            }
          }
        }
        h3 {
          font-size: var(--font-size-small);
          line-height: var(--line-height-small);
          letter-spacing: .05em;
          font-weight: var(--font-weight-bold);
          margin-top: var(--space-small);
          @include mq(tbl) {
            margin-top: var(--space-large);
            font-size: var(--font-size-large);
          }
          a {
            display: block;
            color: var(--color-text-on-accent);
            @include mq {
              @include hoverColor(var(--color-accent));
            }
          }
        }
        .price {
          margin-top: var(--space-small);
          text-align: right;
          @include mq(tbl) {
            margin-top: var(--space-large);
          }
          .sale {
            font-size: var(--font-size-xxxsmall);
            text-decoration: line-through;
            @include mq(tbl) {
              font-size: var(--font-size-medium);
            }
          }
          .regular {
            margin-top: 4px;
            font-size: var(--font-size-medium);
            font-weight: var(--font-weight-bold);
            @include mq(tbl) {
              font-size: 2.6rem;
            }
            span {
              font-size: var(--font-size-xxxsmall);
              @include mq(tbl) {
                font-size: var(--font-size-medium);
              }
            }
          }
        }
        .p_button{
          margin-top: 15px;
          padding: 13px 10px 14px;
          font-size: 12px;
          background-color: var(--color-surface);
          color: var(--color-brand) !important;
          font-weight: var(--font-weight-bold);
          @include mq(tbl) {
            margin-top: var(--space-large);
            font-size: var(--font-size-medium);
            padding: 17px 10px 20px;
            @include hoverBGColor(var(--color-accent));
            @include hoverColor(var(--color-text-on-accent));
          }
          &::after, &::before {
            content: none;
          }
        }
      }
    }
  }
}
.top_section02 {
  background-image:url(https://gigaplus.makeshop.jp/matsuotegami/images/bg_tile.jpg);
  padding-bottom: 115px;
  @include mq {
    padding-bottom: 200px;
  }
}
.top_tondabayashi {
  margin-top: 110px;
  @include mq {
    margin-top: 155px;
  }
  .inner {
    @include container;
    @include mq {
      display: grid;
      grid-template: 
        "title desc" auto
        / 25% 75%;
    }
    .title {
      display: flex;
      flex-direction: row-reverse;
      @include mq {
        grid-area: title;
        display: block;
      }
      h1 {
        img {
          width: 113px;
          @include sp_pc;
          @include mq {
            width: 160px;
          }
        }
      }
    }
    .desc {
      color: var(--color-brand);
      @include mq {
        grid-area: desc;
      }
      h2 {
        font-size: 2.0rem;
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-large);
        @include mq {
          font-size: var(--font-size-xlarge);
        }
        br {
          @include mq {
            display: none;
          }
        }
      }
      p {
        margin-top: 30px;
        font-size: var(--font-size-medium);
        line-height: var(--line-height-large);
        @include mq {
          margin-top: var(--space-medium);
        }

      }
      video {
        margin-top: 55px;
        width: 100%;
        border: 2px solid var(--color-accent);
        padding: 10px;
        @include mq {
          margin-top: var(--space-xxlarge);
          padding: 20px;
        }
      }
    }
  }
}
.top_section03 {
  background-color: var(--color-brand);
}
.top_goen_01 {
  color: var(--color-text-on-accent);
  overflow: hidden;
  @include mq {
    padding: 150px 0 284px;
    position: relative;
  }
  .obj_01 {
    @include mq {
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 270px;
      @include mq {
        width: 522px;
      }
    }
  }
  h1 {
    margin-top: 14px;
    text-align: center;
    img {
      width: 131px;
      @include mq {
        width: 159px;
      }
    }
  }
  .obj_02 {
    padding-right: 30px;
    text-align: right;
    margin-top: -70px;
    @include mq {
      padding-right: pc(257px);
      margin-top: -56px;
    }
    img {
      width: 70px;
      @include mq {
        width: 110px;
      }
    }
  }
  h2 {
    margin-top: 10px;
    @include container;
    text-align: center;
    font-size: 2.0rem;
    font-weight: var(--font-weight-bold);
    @include mq {
      margin-top: -60px;
      font-size: var(--font-size-xlarge);
    }

  }
  p {
    @include container;
    margin-top: 30px;
    font-size: var(--font-size-medium);
    line-height: 2.3;
    @include mq {
      padding: 0;
      width: 560px;
      margin-inline: auto;
    }
  }
  .obj_03 {
    text-align: right;
    @include mq {
      position: absolute;
      right: -70px;
      bottom: 0;
    }
    img {
      width: 353px;
      @include mq {
        width: 739px;
      }
    }
  }
}
.top_goen_02 {
  margin-top: 85px;
  @include container;
  color: var(--color-text-on-accent);
  @include mq {
    margin-top: 35px;
  }
  .unit {
    @include mq {
      display: flex;
      align-items: center;
      &.reverse {
        flex-direction: row-reverse;
        .text {
          padding-left: unset;
          padding-right: 105px;
        }
      }
    }
    .photo {
      @include mq {
        width: 50%;
      }
      img {
        width: 100%;
      }
    }
    .text {
      @include mq {
        width: 50%;
        padding-left: 65px;
      }
      h3 {
        margin-top: 30px;
        font-size: 2.0rem;
        line-height: var(--line-height-large);
        font-weight: var(--font-weight-bold);
        @include mq {
          margin-top: 0;
          font-size: var(--font-size-xlarge);
        }
      }
      p {
        margin-top: 20px;
        font-size: var(--font-size-small);
        line-height: var(--line-height-xlarge);
        line-height: 2.3;
        @include mq {
          font-size: var(--font-size-medium);
        }
      }
    }
  }
  .obj_05 {
    margin-top: 25px;
    padding-left: 16px;
    padding-bottom: 60px;
    @include mq {
      margin-top: unset;
      padding-bottom: unset;
      padding-left: 300px;
      transform: translateY(40px);
    }
    img {
      width: 93px;
      @include mq {
        width: 125px;
      }
    }
  }
  .button{
    margin-top: 50px;
    @include mq {
      margin-top: 150px;
      text-align: center;
    }
    a {
      background-color: var(--color-surface);
      color: var(--color-brand) !important;
      font-weight: var(--font-weight-bold);
      @include mq {
        padding-block: 22px;
        font-size: var(--font-size-small);
        width: 300px;
        @include hoverBGColor(var(--color-accent));
        @include hoverColor(var(--color-text-on-accent));
      }
      &::before {
        background-image: var(--arrow-button-b);
        right: 17px;
        @include mq {
          width: 24px;
          right: 15px;
        }
      }
    }
  }
  .obj_07 {
    text-align: right;
    margin-top: 60px;
    transform: translateY(10px) translateX(10px);
    @include mq {
      transform: translateY(15px) translateX(60px);
    }
    img {
      width: 159px;
      @include mq {
        width: 268px;
      }
    }
  }
}
.top_section04 {
  padding-block: 110px;
  @include container;
  overflow: hidden;
  background-image:url(https://gigaplus.makeshop.jp/matsuotegami/images/bg_tile.jpg);
  @include mq {
    padding-block: 200px 150px;
  }

}
.top_cook {
  .inner {
    @include mq {
      display: grid;
      grid-template:
        "text text text title" auto
        / 1fr 1fr 1fr 1fr;
    }
    .title {
      @include mq {
        grid-area: title;
        justify-self: self-end;
      }
      h1 {
        text-align: right;
        img {
          width: 93px;
          @include sp_pc;
          @include mq {
            width: 150px;
          }
        }
      }
    }
    .text {
      margin-top: 20px;
      @include mq {
        grid-area: text;
        padding-top: 48px;
        margin-top: unset;
      }
      video {
        width: 100vw;
        margin-inline: calc(50% - 50vw);
        border-top: 2px solid var(--color-accent);
        border-bottom: 2px solid var(--color-accent);
        padding-block: 10px;
        @include mq {
          width: 100%;
          margin-inline: unset;
          padding: 20px;
          border: 2px solid var(--color-accent);
        }
      }
      .button {
        margin-top: 30px;
        text-align: center;
        @include mq {
          margin-top: 40px;
          text-align: left;
        }
        a {
          width: 230px;
          min-width: unset;
          @include mq {
            width: 300px;
            padding-block: 20px;
            font-size: var(--font-size-small);
          }
          &::before {
            right: 15px;
          }
        }
      }
    }
  }
}
.top_news {
  margin-top: 224px;
  @include mq {
    margin-top: 176px;
  }
  .inner {
    background-color: var(--color-surface);
    padding: 40px 23px 30px 21px;
    display: flex;
    gap: 17px;
    @include mq {
      padding: 54px 53px 46px 40px;
      gap: 32px;
    }
    h1 {
      width: 53px;
      margin-top: -60px;
      @include mq {
        width: 77px;
        margin-top: -84px;
      }
      img {
        width: 53px;
        @include sp_pc;
        @include mq {
          width: 77px;
        }
      }
    }
    .navigation.newslist {
      margin-top: unset;
      flex: 1;
      .inner {
        padding: unset;
      }
    }
  }

}
.top_seasonal {
  margin-top: 110px;
  @include mq {
    margin-top: 150px;
  }
  .inner {
    @include mq {
      display: grid;
      grid-template:
        "images images title" auto
        / 1fr 1fr 1fr;
      gap: 40px;
    }
    .title {
      @include mq {
        grid-area: title;
        justify-self: self-end;
      }
      h1 {
        text-align: right;
        img {
          width: 118px;
          @include sp_pc;
          @include mq {
            width: 138px;
          }
        }
      }
    }
    .images {
      @include mq {
        grid-area: images;
        display: grid;
        grid-template:
          "ig line" auto
          "store store" auto
          / 1fr 1fr;
        gap: 40px;
        padding-top: 50px;
      }
      a {
        display: block;
        margin-top: 30px;
        aspect-ratio: 315 / 371;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 35px 30px;
        position: relative;
        @include mq {
          margin-top: unset;
          padding: 30px 30px;
          &:hover {
            &::after {
              transform: translateX(10px);
            }
          }
        }
        &::after {
          @include pd;
          right: 20px;
          bottom: 20px;
          width: 40px;
          aspect-ratio: 1 / 1;
          background-image: var(--arrow-button-b-on-accent);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transition: transform var(--transition-speed-fast) var(--bezier);
          @include mq {
            right: 25px;
          }
        }
        &.ig {
          background-image: url(https://gigaplus.makeshop.jp/matsuotegami/images/top_seasonal_01.jpg);
          @include mq {
            grid-area: ig;
          }
        }
        &.line {
          background-image: url(https://gigaplus.makeshop.jp/matsuotegami/images/top_seasonal_02.jpg);
          @include mq {
            grid-area: line;
          }
        }
        &.store {
          background-image: url(https://gigaplus.makeshop.jp/matsuotegami/images/top_seasonal_03.jpg);
          @include mq {
            aspect-ratio: 640 / 354;
            grid-area: store;
            background-image: url(https://gigaplus.makeshop.jp/matsuotegami/images/top_seasonal_03_pc.jpg);
          }
        }
      }
      h2 {
        font-size: 2.0rem;
        line-height: var(--line-height-medium);
        color: var(--color-text-on-accent);
        font-weight: var(--font-weight-bold);
        @include mq {
          font-size: var(--font-size-xlarge);
        }
      }
    }
  }
}
