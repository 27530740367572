@charset "UTF-8";
@import "mixins";

.announce {
  background-color: var(--bg-third);
  font-size: var(--font-size-xxsmall);
  padding: var(--space-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fg-inverse);
  font-weight: 700;
  @include mq(tbl){
    font-size: var(--font-size-small);
  }
}
header {
  position: sticky;
  top: 0;
  z-index: 1000;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-bg-header);
    height: var(--height-header);
    gap: 20px;
    width: 100%;
    color: var(--fg-default);
    transition: transform var(--transition-speed) var(--bezier), background var(--transition-speed) var(--bezier);
    padding-right: 30px;
    @include mq(tbl){ 
      height: var(--height-header-pc);
      padding-right: 40px;
    }
    /*
    &:before {
      @include pd;
      width: 100%;
      z-index: 1002;
      background-color: var(--color-bg-header);
      height: var(--height-header);
      transition: filter var(--transition-speed) var(--bezier);
      @include mq(tbl){
        height: var(--height-header-pc);
      }
    }
    &.is-scroll {
      &:before {
      }
    }
    &.is-down {
      transform: translateY(-120%);
    }
    */
    #logo {
      margin-right: auto;
      padding: var(--padding-header-logo);
      @include mq(tbl){
        padding: var(--padding-header-logo-pc);
      }
      a {
        display: block;
        @include hoverOp(.5);
        svg, img {
          &.sp {
            width: var(--width-header-logo);
            translate: 0 2px;
            @include mq(tbl){
              display: none;
            }
          }
          &.pc {
            width: var(--width-header-logo-pc);
            display: none;
            @include mq(tbl){
              display: inline-block;
              translate: 0 5px;
            }
          }
        }
      }
    }
    .icons {
      display: flex;
      align-items: center;
      gap: 20px;
      a, div {
        height: var(--height-header);
        width: var(--width-header-icon);
        padding: unset;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        @include hoverOp(.5);
        cursor: pointer;
        @include mq(tbl) {
          display: flex;
          height: var(--height-header-pc);
          width: var(--width-header-icon-pc);
          transition: background var(--transition-speed-fast) var(--bezier);
        }
        i {
          display: block;
          padding: unset;
          img {
            width: var(--width-header-icon-image);
            @include mq(tbl) {
              width: var(--width-header-icon-image-pc);
            }
          }
        }
        span {
          display: block;
          color: var(--color-text-on-accent);
          font-size: var(--font-size-xxxsmall);
          white-space: nowrap;
          transform: scale(.8);
          @include mq(tbl) {
            font-size: var(--font-size-xsmall);
            transform: scale(1);
          }
        }
        .quantity {
          background-color: var(--color-accent);
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -12px;
          top: 50%;
          min-width: 14px;
          height: 14px;
          transform: translateY(-100%);
          color: var(--color-text-on-accent);
          font-size: var(--font-size-xxxsmall);
          border-radius: var(--border-radius-circle);
          padding: 0 var(--space-xxsmall);
          line-height: -2px;
          @include mq {
            min-width: 20px;
            height: 20px;
            font-size: var(--font-size-xxsmall);
            border-radius: 10px;
            right: -12px;
          }
          span {
            transform: translateY(-1px);
          }
        }
      }
    }
    #menuButton {
      cursor: pointer;
      @include hoverOp(.5);
      img {
        width: 23px;
      }
    }
  }
}

#gnav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: var(--color-menu-bg);
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform var(--transition-speed) var(--bezier);
  display: flex;
  flex-direction: column;
  padding-bottom: var(--space-xxxlarge);
  height: 100%;
  @include mq(tbl){
    width: 375px;
    left: auto;

  }
  &.show {
    transform: translateX(0);
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    min-height: var(--height-header);
    border-bottom: 1px solid var(--border-sub);
    .logo {
      padding: var(--padding-header-logo);
      svg, img {
        width: var(--width-header-logo);
        translate: 0 2px;
      }
    }
    #closeButton {
      cursor: pointer;
      @include hoverOp(.5);
      img {
        width: 23px;
      }
    }
  }
  .icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 45px;
    padding-bottom: 10px;
    padding-inline: 45px;
    gap: 45px 0;
    li {
      a {
        font-size: var(--font-size-medium);
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 8px;
        @include hoverOp(.5);
        i {
          img {
            width: 23px;
          }
        }
      }
    }
  }
  h2 {
    margin-top: 35px;
    font-size: var(--font-size-xsmall);
    padding-bottom: var(--space-large);
    border-bottom: 1px solid var(--border-third);
    margin-inline: var(--padding-sp);
  }
  .utils {
    margin-inline: var(--padding-sp);
    margin-top: 10px;
    h3, p {
      font-size: var(--font-size-medium);
      padding: 8px 0 ;
      margin-top: 10px;
    }
    h3 {
      cursor: pointer;
      display: block grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      @include hoverOp(.5);
      &::after {
        content: '';
        background-image: var(--arrow-bottom);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 13px;
        height: 1lh;
        transition: rotate var(--transition-speed-fast) var(--bezier);
      }
      &.active {
        &::after {
          rotate: z 180deg;
        }
      }

    }
    ul {
      margin-left: 20px;
      margin-top: 10px;
      li {
        a {
          font-size: var(--font-size-small);
          display: block;
          padding-block: 12px;
          padding-right: 3px;
          display: block grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          @include hoverOp(.5);
          &::after {
            content: '';
            background-image: var(--arrow-right);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 7px;
            height: 1lh;
          }
        }
      }

    }

  }
  .guide {
    margin-inline: var(--padding-sp);
    margin-top: 10px;
    li {
      a {
        font-size: var(--font-size-small);
        display: block;
        line-height: 1.6;
        padding: 6px 0;
        @include hoverOp(.5);
      }
    }
  }
}

#headerSearch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  background-color: var(--color-menu-bg);
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  transform: translateY(-100%);
  transition: transform var(--transition-speed) var(--bezier);
  display: flex;
  flex-direction: column;
  padding-bottom: var(--space-xxxlarge);
  padding: 82px var(--padding-sp);
  &.show {
    transform: translateY(0);
  }
  #headerSearchCloseButton {
    cursor: pointer;
    @include hoverOp(.5);
    position: absolute;
    right: 26px;
    top: 17px;
    img {
      width: 23px;
    }
  }
  .searchbox {
    transition: border var(--transition-speed-fast) var(--bezier);
    background-color: var(--bg-button);
    border-radius: var(--border-radius-pill);
    transition: background-color var(--transition-speed-fast) var(--bezier);
    &:has(input[type="text"]:focus) {
      background-color: #e6e6e6;
    }
    .searchbox-inner {
      display: flex;
      align-items: center;
      gap: var(--space-xsmall);
      padding-inline: var(--space-medium);
      .icon {
        img {
          width: 13px;
        }
      }
      input[type="text"] {
        border: none;
        padding-block: var(--space-xsmall);
        width: 100%;
        font-size: var(--font-size-medium);
        flex: 1;
        background-color: transparent;
        @include mq(tbl){
          font-size: var(--font-size-xsmall);
          padding-top: var(--space-small);
          padding-bottom: var(--space-small);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .categories {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 10px;
    li {
      a {
        display: block;
        font-size: 1.3rem;
        line-height: 1;
        padding: 6px var(--space-large);
        border-radius: var(--border-radius-xsmall);
        border: 1px solid var(--border-third);
        background: #FFF;
      }
    }
  }

}