@charset "UTF-8";

@import "mixins";

.navigation.breadcrumbs {
  display: none;
  @include mq(tbl) {
    display: block;
  }
  .m_localnav_bottom + & {
    margin-top: unset;
  }
  .inner {
    @include container;
    @include mq(tbl) {
      @include container(var(--padding-sp),wide);
      margin-top: 32px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        color: var(--color-text);
        position: relative;
        margin-right: var(--space-xsmall);
        font-size: var(--font-size-xsmall);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          font-size: 1.3rem;
        }
        &:after {
          content: '＞';
          margin-left: var(--space-xsmall);
          color: var(--color-text);
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
        a {
          color: var(--color-text);
          @include hoverColor(var(--color-text-weak));
        }
      }
    }
  }
}
