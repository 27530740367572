@charset "UTF-8";

@import "mixins";

.navigation.localnav {
  background-color: var(--color-surface-weaker);
  .inner {
    .overflow {
      overflow: auto;
      @include mq(tbl) {
        overflow: visible;
        padding: unset;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        @include mq(tbl) {
          justify-content: center;
          flex-wrap: wrap;
        }
        li {
          font-size: var(--font-size-xsmall);
          white-space: nowrap;
          @include mq(tbl) {
            font-size: var(--font-size-small);
            &:last-child {
              padding-right: 0;
            }
          }
          &.active,&.current_page_item {
            a {
              color: var(--color-text-on-accent);
              background-color: var(--color-brand);
              font-weight: var(--font-weight-bold);
            }
          }
          a {
            display: block;
            position: relative;
            transition: color var(--transition-speed) var(--bezier), opacity var(--transition-speed) var(--bezier);
            padding: var(--space-medium);
            color: var(--color-text);
            letter-spacing: 0;
            @include mq(tbl) {
              padding: var(--space-large);
              @include hoverOp;
            }
          }
        }
      }
    }
  }
}
