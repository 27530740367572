@charset "UTF-8";

@import "mixins";

.component.type_catchcopy_image_bg {
  margin-top: var(--margin-top-large);
  @include mq(tb) {
    @include container;
    padding-bottom: 40px;
  }
  @include mq(tbl) {
    margin-top: var(--margin-top-xlarge);
  }
  .inner {
    @include mq(tb) {
      position: relative;
    }
    .image {
      width: var(--width-catchcopy-image-bg-image);
      @include mq(tbl) {
        width: var(--width-catchcopy-image-bg-image-pc);
      }
      img {
        aspect-ratio: var(--aspect-ratio-catchcopy-image);
        object-fit: cover;
        @include mq(tbl) {
          width: var(--width-catchcopy-image-bg-image-pc);
        }
      }
    }
    .text {
      background-color: var(--bg-default);
      padding: 40px;
      width: var(--width-catchcopy-image-bg-text);
      max-width: 400px;
      position: relative;
      margin-top: -15vw;
      margin-left: auto;
      @include mq(tb) {
        position: absolute;
        right: 0;
        margin-top: unset;
        bottom: -50px;
        width: var(--width-catchcopy-image-bg-text-pc);
      }
      @include mq(tbl) {
        padding: 40px 55px;
      }
      h3 {
        @include headingMedium;
        font-family: var(--font-family);
        font-weight: 700;
        display: inline-block;
        padding-bottom: 12px;
        font-size: var(--font-size-small);
        @include mq(tbl) {
          padding-bottom: 10px;
          font-size: 1.5rem;
        }
        &::before {
          width: 40px;
          @include mq(tbl) {
            width: 56px;
          }
        }
      }
      .copy {
        h4 {
          margin-top: var(--space-xlarge);
          font-size: 2.0rem;
          line-height: var(--line-height-small);
          font-weight: 700;
          word-break: auto-phrase;
          @include mq(tbl) {
            margin-top: var(--space-xlarge);
          }
        }
      }
      .desc {
        margin-top: var(--space-xlarge);
        @include mq(tbl) {
          margin-top: var(--space-xxlarge);
        }
        p {
          font-size: 1.5rem;
        }
      }
      .button {
        margin-top: var(--space-xxlarge);
        text-align: left;
        @include mq(tbl) {
          margin-top: 50px;
        }
        a {
          text-align: center;
          padding: 15px 48px;
          font-weight: var(--font-weight-bold);
          @include mq {
            font-size: var(--font-size-small);
            padding: 16px 48px;
            min-width: 100%;
          }
        }
      }
    }
  }
  &.reverse {
    .inner {
      .image {
        margin-left: auto;
      }
      .text {
        margin-left: 0;
        margin-right: auto;
        @include mq(tb) {
          right: auto;
          left: 0;
        }
      }
    }
  }
}
