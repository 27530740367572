@charset "UTF-8";

@import "mixins";

.navigation.pagenate {
  @include container;
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .m_newslist + & {
    .inner {
      padding-top: 0;
      border-top: none;
    }
  }
  .inner {
    a, span {
      text-align: center;
      display: block;
      width: var(--width-pagenate);
      height: var(--width-pagenate);
      line-height: var(--width-pagenate);
      // background-color: var(--color-surface);
      color: var(--color-text);
      font-size: var(--font-size-xsmall);
      // border: 1px solid var(--color-border);
      transition: color var(--transition-speed-fast) var(--bezier);
      @include hoverOp(.5);
      @include mq(tbl) {
        width: var(--width-pagenate-pc);
        height: var(--width-pagenate-pc);
        line-height: var(--width-pagenate-pc);
        font-size: var(--font-size-small);
      }
      img {
        width: var(--width-pagenate-image);
        vertical-align: middle;
        transform: translateY(-1px);
        @include mq(tbl) {
          width: var(--width-pagenate-image-pc);
        }
      }
      &.current, &.current_page {
        // background-color: var(--color-brand);
        // color: var(--color-text-on-accent);
        position: relative;
        &::after {
          @include pd;
          height: 1px;
          width: var(--space-gap-padding-xs, 8px);
          bottom: -4px;
          left: 50%;
          transform: translateX(-50%);
          background-color: var(--fg-default);
        }
        @include mq(tbl) {
          &:hover {
            opacity: 1;
          }
        }
      }
      &.previouspostslink, &.nextpostslink, &.link_before, &.link_next {
        // background-color: transparent;
      }
      &.previouspostslink, &.link_before {
        img {
          transform: scale(-1, 1) translateY(-1px);
        }
      }
    }
    // wordpress
    .wp-pagenavi {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a, span {
        margin: 0 var(--space-xxsmall) var(--space-small);
        @include mq(tbl) {
          margin: 0 var(--space-xsmall) var(--space-medium);
        }
      }
    }
    // mt
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 var(--space-xxsmall) var(--space-small);
        @include mq(tbl) {
          margin: 0 var(--space-xsmall) var(--space-medium);
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
