@charset "UTF-8";

@import "mixins";

.navigation.newslist {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.typeB {
    margin-top: 25px;
    @include mq(tbl) {
      margin-top: 25px;
    }
    .inner {
      ul {
        width: auto;
        li {
          a {
            padding: var(--space-small) 0;
            @include mq(tbl) {
              padding: var(--space-xlarge) 0;
              position: relative;
              gap: 45px;
              width: 390px;
              margin-inline: auto;
            }
          }
        }
        &:has(> :nth-child(2)) {
          li {
            border-bottom: 1px solid var(--border-third);
            margin-top: -1px;
            &:first-child {
              border-top: 1px solid var(--border-third);
            }
          }
        }
      }
    }
  }
  .inner {
    @include container;
    ul {
      width: 100%;
      @include mq {
        width: 390px;
        margin-inline: auto;
      }
      li {
        a {
          display: block;
          padding: var(--space-xsmall) 0;
          color: var(--color-text);
          display: flex;
          align-items: flex-start;
          gap: 25px;
          @include mq(tbl) {
            padding: var(--space-small) 0;
            position: relative;
            gap: 45px;
          }
          .date {
            font-size: var(--font-size-small);
            line-height: var(--line-height-medium);
            width: 5.5em;
          }
          .cat {
            display: inline-block;
            text-align: center;
            background-color: var(--color-brand-weak);
            color: var(--color-text-on-accent);
            font-size: var(--font-size-xxsmall);
            padding: var(--space-xxsmall) var(--space-xsmall);
            margin-left: var(--space-xsmall);
            min-width: 90px;
            @include mq(tbl) {
              width: 12em;
              font-size: var(--font-size-xsmall);
              min-width: var(--min-width-newslist-category);
              margin: 0;
              padding: var(--space-xsmall) var(--space-xsmall);
            }
          }
          h2 {
            position: relative;
            font-size: var(--font-size-small);
            line-height: var(--line-height-medium);
            flex: 1;
            @include mq(tbl) {
              @include hoverColor(var(--color-text-weak));
            }
          }
        }
      }
    }
  }
}
