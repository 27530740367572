@charset "UTF-8";

@import "mixins";

.navigation.maincatnav {
  // margin-top: var(--margin-top-xsmall);
  border-top: 1px solid var(--border-sub);
  @include mq(tbl) {
    // margin-top: var(--margin-top-small);
  }
  .inner {
    @include container;
    .overflow {
      overflow: auto;
      @include mq(tbl) {
        overflow: visible;
        padding: unset;
      }
      ul {
        display: flex;
        justify-content: center;
        li {
          font-size: 1.5rem;
          white-space: nowrap;
          position: relative;
          a {
            display: block;
            position: relative;
            padding: var(--space-xlarge) var(--space-small);
            &:hover {
              @include mq(tbl) {
                @include hoverOp;
              }
            }
          }
        }
      }
    }
  }
}
