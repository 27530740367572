@charset "UTF-8";
@import "mixins";

.component.type_heading.large:has(+.m_cart) .inner .heading {
  border: none;
}
.m_cart {
  @include container;
  @include mq(tbl) {
    margin-top: var(--margin-top-small);
  }
  .empty {
    font-size: var(--font-size-small);
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge);
  }
  .cart_header {
    display: none;
    @include mq(tbl){
      background-color: #F1EFEF;
      color: var(--fg-default);
      font-size: var(--font-size-small);
      padding: var(--space-medium);
      display: grid;
      grid-template: 
      "photo title amount point price delete" auto
      / auto 1fr 12% 8% 18% 5%;
      ;
      gap: 20px;
    }
    .photo {
      grid-area: photo;
    }
    .title {
      grid-area: title;
    }
    .amount {
      grid-area: amount;
      text-align: center;
      white-space: nowrap;
    }
    .point {
      grid-area: point;
      text-align: center;
      white-space: nowrap;
    }
    .price {
      grid-area: price;
      text-align: center;
      white-space: nowrap;
    }
    .delete {
      grid-area: delete;
      white-space: nowrap;
    }
  }
  .cart_list {
    border-top: 1px solid var(--border-sub);
    @include mq(tbl) {
      border-top: none;
    }
    .unit {
      border-bottom: 1px solid var(--border-third);
      padding-top: var(--space-medium);
      padding-bottom: var(--space-large);
      display: grid;
      gap: var(--space-large) var(--space-medium);
      grid-template: 
        "photo title title delete" auto
        "amount point price price" auto
        / auto 1fr auto auto;
      ;
      @include mq(tbl) {
        grid-template: 
          "photo title amount point price delete" auto
          / auto 1fr 12% 8% 18% 5%;
        ;
        gap: 20px;
        align-items: center;
      }
      .photo {
        grid-area: photo;
        img {
          max-height: 107px;
          width: auto;
          aspect-ratio: 1/1;
          object-fit: contain;
          @include mq(tbl) {
            max-height: 95px;
            @include hoverOp;
          }
        }
      }
      h2 {
        grid-area: title;
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        a {
          @include mq(tbl) {
            @include hoverColor(var(--color-brand-weak));
          }
        }
      }
      .amount {
        grid-area: amount;
        display: flex;
        align-items: stretch;
        align-self: center;
        white-space: nowrap;
        input {
          text-align: center;
          appearance: none;
          border-radius: 0;
          color: var(--fg-default);
          width: 70px;
          border-radius: var(--border-radius-small);
          appearance: none;
          border: 1px solid var(--border-sub);
          font-size: var(--font-size-medium);
          padding: var(--space-xxsmall);
          @include mq(tbl) {
            &:hover {
              border-color: var(--border-default);
            }
          }
        }
        .update {
          display: flex;
          align-items: center;
          font-size: var(--font-size-xsmall);
          padding-left: 10px;
          @include mq(tbl) {
            @include hoverOp;
          }
        }
      }
      .point {
        grid-area: point;
        align-self: center;
        justify-self: self-end;
        font-size: var(--font-size-small);
        @include mq(tbl) {
          justify-self: center;
        }
      }
      .price {
        grid-area: price;
        text-align: right;
        font-size: var(--font-size-large);
        align-self: center;
        white-space: nowrap;
        transform: translateY(-2px);
        @include mq(tbl) {
          font-size: var(--font-size-xlarge);
        }
        span {
          font-size: var(--font-size-small);
        }
      }
      .delete {
        grid-area: delete;
        text-align: center;
        padding-top: var(--space-xxsmall);
        a {
          display: block;
          @include hoverOp;
          img {
            width: 20px;
          }
        }
      }
    }
  }
  .total {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: var(--space-large);
    font-size: var(--font-size-medium);
    dt {


    }
    dd {
      min-width: 150px;
      text-align: right;
      strong {
        font-size: var(--font-size-large);
        @include mq(tbl) {
          font-size: var(--font-size-xlarge);
        }
      }
    }
    + .buttons, + .amazon {
      margin-top: var(--space-xxlarge);
    }
  }
  .deliveryfee {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: var(--space-small);
    font-size: var(--font-size-medium);
    dt {


    }
    dd {
      min-width: 120px;
      text-align: right;
      strong {
        font-weight: var(--font-weight-normal);
      }
    }
  }
  .free-shipping {
    margin-top: var(--space-large);
    margin-bottom: var(--space-xxlarge);
    text-align: right;
    p {
      font-size: var(--font-size-small);
      line-height: var(--line-height-medium);
      em {
        font-weight: var(--font-weight-bold);
        color: #AD5539;
      }
    }

  }
  .amazon {
    text-align: right;
    margin-bottom: var(--space-medium);
  }
  .buttons {
    .forward {
      @include mq {
        display: flex;
        justify-content: flex-start;
        gap: var(--space-small);
        flex-direction: row-reverse;
      }
    }
    a {
      flex: 1;
      color: white;
      text-align: center;
      display: block;
      font-weight: var(--font-weight-bold);
      @include mq(tbl) {
        max-width: 280px;
        padding-top: 18px;
        padding-bottom: 18px;
      }
      &.order {
        background-color: var(--fg-default);
        border-color: var(--fg-default);
        color: var(--color-text-on-accent) !important;
        margin-bottom: var(--space-xlarge);
        transition: all var(--transition-speed-fast) var(--bezier);
        padding: 19px 20px;
        font-size: var(--font-size-small);
        &::before {
          right: 21px;
        }
        @include mq(tbl) {
          margin-bottom: 0;
          @include hoverBGColor(var(--fg-third));
          font-size: 1.3rem;
          padding-block: 17px 19px 15px;
          &:hover {
            border-color: var(--color-brand-weak);
          }
        }
      }
      &.continue {
        background-color: var(--color-surface);
        padding: 19px 20px;
        font-size: var(--font-size-small);
        color: var(--fg-default) !important;
        border: 1px solid var(--border-sub);
        &::before {
          background-image: var(--arrow-button-b);
          right: 21px;
        }
        @include mq(tbl) {
          @include hoverBGColor(var(--fg-default));
          font-size: 1.3rem;
          padding-block: 17px 19px 15px;
          &:hover {
            border-color: var(--fg-default);
            color: var(--color-text-on-accent) !important;
            &::before {
              background-image: var(--arrow-button-b-on-accent);
            }
          }
        }
      }
    }
  }
}