@charset "UTF-8";

@import "reset";

@import "mixins";


$responsive-debug-mode: false;

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
outline: none;
}
html {
  font-size: 62.5%;
  height: 100%;
  scroll-padding-top: calc(var(--height-header) + 30px);
  @include mq(tbl){
    scroll-padding-top: calc(var(--height-header-pc) + 35px);
  }
}
body {
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: var(--font-family);
  font-optical-sizing: auto;
  font-weight: var(--font-weight-normal);
  font-feature-settings: var(--font-feature-settings);
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  &.fixed {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    @include mq(tbl) {
      position: static;
    }
    &::before {
      opacity: 1;
      z-index: 2;
      transition: opacity var(--transition-speed) var(--bezier) .1s, z-index 0s var(--bezier);
    }
  }
  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: rgba(255,255,255,.6);
    z-index: -2;
    opacity: 0;
    transition: opacity var(--transition-speed) var(--bezier) 0s, z-index 0s var(--bezier) .4s;
  }
  @if $responsive-debug-mode {
    &:after {
      content: 'min';
      position: fixed;
      left: 10px;
      top: 10px;
      font-size: 12px;
      background-color: red;
      color: white;
      z-index: 1000;
      display: inline-block;
      padding: 4px;
      @include mq(sp) {
        content: 'sp';
      }
      @include mq(spl) {
        content: 'sp landscape';
      }
      @include mq(tb) {
        content: 'tablet';
      }
      @include mq(tbl) {
        content: 'tablet landscape';
      }
      @include mq(pc) {
        content: 'pc';
      }
    }
  }
  &.fixed {
    position: fixed;
    width: 100%;
  }
}

.red {
  color: var(--color-text-error);
}
strong {
  font-weight: var(--font-weight-bold);
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: currentColor;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &[href^="tel:"] {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}
abbr {
  text-decoration: none;
}
button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  // width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  @include mq(tbl) {
    // width: auto;
  }
}
.hidden-over-pc {
  @include mq(pc) {
    display: none;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none;
  }
}
.hidden-sp {
  @media (max-width: 374px) {
    display: none;
  }
}
.hidden-tb {
  @media (max-width: 767px) {
    display: none;
  }
}
.hidden-tbl {
  @media (max-width: 1079px) {
    display: none;
  }
}

.anchor {
  position: relative;
  top: 0;
  display:block;
  visibility:hidden;
  color: transparent;
  &_sub {
    position: relative;
    top: 0;
    display:block;
    visibility:hidden;
    color: transparent;
    @include mq(tbl) {
      top: -100px;
    }
  }
}
.svg_def {
  visibility:hidden;
  width: 0;
  height: 0;
  position: absolute;
}
.container {
  @include container;
}
main {
  &.webpage {
    padding-top: var(--height-header);
    @include mq(tbl) {
      padding-top: calc(var(--height-header-pc) + 53px);
    }
  }
}
.p_button {
  display: inline-block;
  text-decoration: none !important;
  position: relative;
  line-height: 1;
  padding: 14px var(--space-xxlarge);
  min-width: var(--min-width-button);
  color: var(--color-text-on-accent) !important;
  font-size: 1.35rem;
  font-weight: var(--font-weight-button);
  text-align: center;
  cursor: pointer;
  background-color: var(--fg-default);
  @include mq(tbl) {
    line-height: 1;
    min-width: var(--min-width-button-pc);
    transition: border var(--bezier) var(--transition-speed-fast), background-color var(--bezier) var(--transition-speed-fast), color var(--bezier) var(--transition-speed-fast);
    padding-block: var(--space-large);
    &:hover {
      background-color: var(--fg-third);
    }
  }
  /*
  &:before {
    @include pd;
    background: center / contain no-repeat var(--arrow-button-b-on-accent);
    width: 20px;
    height: 11px;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
    color: var(--color-text-on-accent) !important;
    @include mq(tbl) {
      right: 14px;
    }
  }
    */
  &[target="_blank"] {
    &:before {
      background-image: var(--arrow-newwin);
      width: 13px;
      height: 12px;
    }
  }
  &.cv {
    line-height: 1;
    padding: var(--space-medium) var(--space-xxxlarge);
    min-width: var(--min-width-button-apply);
    font-size: var(--font-size-medium);
    &::before {
      width: 20px;
      height: 11px;
      @include mq(tbl) {
        right: 20px;
      }
    }
    @include mq(tbl) {
      padding: var(--space-large) var(--space-xxxlarge);
      min-width: var(--min-width-button-apply-pc);
    }
  }
  &.reverse {
    color: var(--fg-default) !important;
    background: var(--color-surface);
    @include mq(tbl) {
      @include hoverBGColor(var(--fg-third));
      @include hoverColor(var(--fg-inverse));
    }
    &::before {
      //background-image: var(--arrow-brand);
    }
    &[target="_blank"] {
      &:before {
        background-image: var(--arrow-newwin);
      }
    }
    &.cv {
      line-height: 1;
      padding: var(--space-large) var(--space-xlarge);
      min-width: var(--min-width-button-apply);
      font-size: 1.5rem;
      &:before {
        content: none;
      }
      @include mq(tbl) {
        min-width: var(--min-width-button-apply-pc);
        font-size: var(--font-size-large);
      }
    }
  }
}
#wrapper {
  transition: opacity var(--transition-speed) var(--bezier);
  overflow: hidden;
  header + & {
    padding-top: calc(var(--height-header) + 30px);
    @include mq(tbl) {
      padding-top: calc(var(--height-header-pc) + 35px);
    }
  }
  .toppage & {
    padding-top: 0;
  }
  .sink & {
    opacity: .5;
    @include mq(tbl) {
      opacity: 1;
    }
  }
  .contentsInner {
    margin-top: 18px;
    overflow-x: hidden;
    @include mq(tbl) {
      display: flex;
      flex-direction: row-reverse;
      gap: 50px;
      @include container(var(--padding-sp),wide);
      padding-top: 60px;
      padding-bottom: var(--space-xxxlarge);
      margin-top: unset;
    }
    &:has(.itemDetail) {
      margin-top: unset;
    }
    main {
      @include mq(tbl) {
        flex: 1;
        width: 100%;
      }
      > *:first-child {
        margin-top: unset;
      }
  
    }
    #sidebar {
      @include mq(tbl) {
        width: 250px;
      }
    }
  }
  
}
.bgwrapper {
  background-position: center;
  background-size: cover;
  margin-inline: calc(50% - 50vi);
  overflow: hidden;
  > *:first-child {
    margin-top: 0 !important;
  }
}
.codehighlight {
  .copy-btn {
    display: block;
    margin-left: auto;
    width: 140px;
    padding: 10px;
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  pre {
    code {
      max-height: 300px;
      overflow-y: auto;
      font-size: 14px;
      line-height: 20px;
      font-family: Consolas, liberation mono, Menlo, Courier, monospace;
    }
  }
}

html.lenis,
html.lenis body {
  height: auto;
}
.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
html.is-changing .transition-fade {
  transition: opacity 0.15s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

//default
@import "common/header";
@import "common/footer";
@import "common/sidebar";
@import "component/index";
@import "navigation/index";
@import "static/index";
@import "top";
@import "uniq";
@import "cart";
@import "swiper";