@charset "UTF-8";

@import "mixins";

.component.type_heading {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.large {
    .inner {
      @include container;
      .heading {
        @include heading;
        padding: 0 0 var(--space-medium);
        border-bottom: 1px solid var(--border-sub);
        position: relative;
        font-size:2.0rem;
        line-height: var(--line-height-medium);
        word-break: auto-phrase;
        @include mq(tbl) {
          font-size: var(--font-size-xlarge);
          padding-bottom: var(--space-medium);
          border-width: 1px;
        }
        &:has(.en) {
          display: flex;
          align-items: baseline;
          gap: 20px;
          @include mq(tbl) {
            gap: 38px;
          }
        }
        .en {
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-medium);
          font-weight: var(--font-weight-normal);
          font-family: var(--font-family-en);
          transform: translateY(-1px);
          color: var(--fg-sub);
          @include mq(tbl) {
            font-size: var(--font-size-small);
          }
        
        }
      }
    }
  }
  &.medium {
    .inner {
      @include container;
      .heading {
        @include headingMedium;
      }
    }
  }
  .anchor + & {
    // margin-top: 20px;
    @include mq(tbl) {
      // margin-top: 45px;
    }
  }
}
