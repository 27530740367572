@charset "UTF-8";

@import "mixins";

.component.type_num {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  background-color: #E2F3F8;
  padding-top: 30px;
  padding-bottom: 30px;
  @include mq(tbl) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  &.set2 {
    .inner {
      @include mq(tbl) {
        gap: 80px;
      }
    }
  }
  &.set3 {
    .inner {
      @include mq(tbl) {
        gap: 35px;
      }
    }
  }
  &.set3, &.set4 {
    .inner {
      @include mq(tbl) {
        gap: 20px;
      }
      .unit {
        .circle {
          @include mq(tbl) {
            width: 230px;
            height: 230px;
          }
          dl {
            dt {
              @include mq(tbl) {
                top: 40px;
                padding: 0 35px;
              }
            }
            dd {
              @include mq(tbl) {
                top: 80px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
              }
              em {
                @include mq(tbl) {

                }
              }
              span {
                @include mq(tbl) {
                  margin: 0 0 8px 6px;
                }
              }
            }
          }
        }
      }
    }
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    .unit {
      margin-top: 50px;
      @include mq(tbl) {
        margin-top: unset;
      }
      &:first-of-type {
        margin-top: unset;
      }
      .circle {
        width: 300px;
        height: 300px;
        text-align: center;
        background-color: var(--color-bg);
        margin: 0 auto;
        border-radius: 50%;
        position: relative;
        @include mq(tbl) {
          width: 320px;
          height: 320px;
        }
        dl {
          dt {
            @include fs(18,26);
            position: absolute;
            top: 70px;
            padding: 0 30px;
            width: 100%;
            text-align: center;
          }
          dd {
            position: absolute;
            top: 110px;
            width: 100%;
            color: var(--color-brand);
            font-weight: var(--font-weight-bold);
            letter-spacing: 0;
            @include mq(tbl) {
              top: 120px;
            }
            em {
              @include fs(70,70);
              display: block;
              @include mq(tbl) {
                @include fs(80,80);
              }
            }
            span {
              @include fs(24,24);
              display: block;
              margin-top: 25px;
              @include mq(tbl) {

              }
            }
          }
        }
      }
      .desc {
        @include fs(13,22);
        margin-top: 15px;
        @include mq(tbl) {
          margin-top: 20px;
          @include fs(15,24);
        }
      }
    }
  }
}
