@charset "UTF-8";

@import "mixins";

.component.type_conversion {
  margin-top: var(--margin-top-large);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--color-deep-weaker);
  padding-top: var(--padding-convertion);
  padding-bottom: var(--padding-convertion);
  @include mq(tbl) {
    margin-top: var(--margin-top-xxlarge);
    padding-top: var(--padding-convertion-pc);
    padding-bottom: var(--padding-convertion-pc);
  }
  .inner {
    @include container;
    color: var(--color-text-on-accent);
    text-align: left;
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    .image {
      text-align: inherit;
      img {
        max-width: 50%;
        @include mq(tbl) {
          max-width: 25%;
        }
      }
    }
    .copy {
      h4 {
        margin-top: var(--space-large);
        font-size: var(--font-size-xlarge);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-heading);
        font-family: var(--font-family-heading);
        word-break: auto-phrase;
        @include mq(tbl) {
          margin-top: var(--space-xxlarge);
          font-size: var(--font-size-xxxlarge);
        }
      }
    }
    .desc {
      margin-top: var(--space-large);
      @include mq(tbl) {
        margin-top: var(--space-large);
      }
    }
    .button {
      text-align: inherit;
      margin-top: var(--space-xlarge);
      @include mq(tbl) {
        margin-top: var(--space-xxlarge);
      }
      a {
        text-align: center;
      }
    }
  }
}
