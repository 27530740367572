@charset "UTF-8";

@import "mixins";

.component {
  strong, b {
    font-weight: var(--font-weight-bold);
  }
  a {
    color: var(--fg-default);
    text-decoration: underline;
  }
  .text {
    p {
      font-size: 1.5rem;
      line-height: var(--line-height-large);
      padding-bottom: var(--space-xxlarge);
      &.note {
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        color: var(--fg-sub);
        @include mq(tbl) {
          @include fs(14,22);
        }
        padding-bottom: unset;
      }
      &.heading {
        position: relative;
        font-size: 2rem;
        line-height: var(--line-height-small);
        margin-bottom: var(--space-xxlarge);
        padding-bottom: unset;
        font-weight: var(--font-weight-bold);
        @include mq(tbl) {
          margin-top: unset;
        }
      }
      &.figure {
        padding-bottom: var(--space-xlarge);
        margin-top: var(--margin-top-large);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        color: var(--color-text-weak);
        padding-bottom: 0;
        @include mq(tbl) {
          @include fs(14,22);
          padding-bottom: 65px;
        }
        img {
          &.mt-image-left,
          &.mt-image-center,
          &.mt-image-right,
          &.mt-image-none {
            margin-bottom: var(--space-xsmall) !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include mq(tbl) {
              margin-bottom: var(--space-small) !important;
            }
          }
        }
      }
      img {
        &.mt-image-left,
        &.mt-image-center,
        &.mt-image-right {
          margin-bottom: 0 !important;
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
      & + .button{
        margin-top: unset;
      }
    }
    ul, ol {
      font-size: var(--font-size-small);
      line-height: var(--line-height-large);
      padding-bottom: var(--space-large);
      @include mq(tbl) {
        font-size: var(--font-size-medium);
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    ul {
      list-style: disc;
      li {
        margin-left: var(--space-large);
      }
    }
    ol {
      list-style: decimal;
      li {
        margin-left: var(--space-large);
      }
    }
    /*
    .services {
      list-style: none;
      display: grid;
      @include grid(2,10px,10px);
      @include mq(tbl) {
        @include grid(3,10px,10px);
      }
      li {
        border-radius: var(--border-radius);
        margin: 0;
        border: 1px solid #DBDBDB;
        padding: 4px 20px 4px 13px;
        display: flex;
        align-items: center;
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-bold);
        &:last-child {
          margin-right: 0;
        }
        i {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 7px;
          @include mq(tbl) {
            width: 36px;
            height: 36px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    */
    /*
    .icons {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      li {
        border-radius: var(--border-radius);
        margin: 0 10px 10px 0;
        border: 1px solid #DBDBDB;
        padding: 5px 22px;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          padding: 7px 24px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .button {
      margin-top: 15px;
      text-align: right;
      @include mq(tbl) {
        margin-top: 20px;
      }
      a {
        text-align: center;
      }
    }
    */
  }
  .image, .movie, .map {
    text-align: center;
    &.shrink {
      margin-left: auto;
      margin-right: auto;
    }
    img {
      border-radius: var(--border-radius-image);
    }
    a {
      @include mq(tbl) {
        @include hoverOp;
      }
    }
    .caption {
      @include caption;
    }
    .child {
      padding-bottom: var(--space-xlarge);
      @include mq(tbl) {
        padding-bottom: var(--space-xxlarge);
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@import "pagetitle";
@import "extendedtitle";
@import "catchcopy";
@import "catchcopy_image";
@import "catchcopy_image_bg";
@import "conversion";
@import "heading";
@import "text_lead";
@import "text";
@import "text_image";
@import "text_map";
@import "map";
@import "image";
//@import "image_multi";
@import "movie";
//@import "movie_multi";
@import "panel";
@import "speaker";
@import "speaker_heading";
@import "column";
@import "table";
@import "button";
@import "anchor";
@import "num";
@import "wysiwyg";
@import "catalog";
@import "faq";
@import "member";
@import "iframe";
@import "pagelist";
