@charset "UTF-8";

@import "mixins";

#sidebar {
  padding: var(--space-large) var(--space-xlarge) var(--space-xxxlarge);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1004;
  overflow-y: scroll;
  transform: translateY(calc(100% + 150px));
  transition: transform var(--transition-speed) var(--bezier);
  background-color: var(--color-bg);
  @include mq(tbl){
    display: none;
    padding: unset;
    position: static;
    width: auto;
    overflow-y: unset;
    transform: translateY(0);
    transition: none;
    z-index: 1;
    background-color: transparent;
  }
  #wrapper & {
    display: block;
  }
  &.is-show {
    transform: translateY(0);
  }
  .sidebar-header {
    position: relative;
    margin-top: 40px;
    font-size: var(--font-size-small);
    @include heading;
    &:first-of-type {
      margin-top: unset;
    }
  }
  .searchbox {
    margin-top: var(--space-large);
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    transition: border var(--transition-speed-fast) var(--bezier);
    background-color: var(--color-surface);
    &:has(input[type="text"]:focus) {
      border-color: var(--fg-sub);
    }
    .searchbox-inner {
      display: flex;
      align-items: center;
      gap: var(--space-xsmall);
      padding-left: var(--space-small);
      padding-right: var(--space-small);
      input[type="text"] {
        border: none;
        padding-top: var(--space-small);
        padding-bottom: var(--space-small);
        padding-right: var(--space-medium);
        width: 100%;
        font-size: var(--font-size-medium);
        flex: 1;
        text-align: center;
        @include mq(tbl){
          font-size: var(--font-size-xsmall);
          padding-top: var(--space-small);
          padding-bottom: var(--space-small);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .categories {
    margin-top: 80px;
    h3 {
      cursor: pointer;
      display: block grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      margin-top: var(--space-xxxlarge);
      font-size: var(--font-size-medium);
      border-bottom: 1px solid var(--border-third);
      padding-bottom: var(--space-xlarge);
      @include hoverOp(.5);
      &::after {
        content: '';
        background-image: var(--arrow-bottom);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 13px;
        height: 1lh;
        transition: rotate var(--transition-speed-fast) var(--bezier);
      }
      &.active {
        &::after {
          rotate: z 180deg;
        }
      }

    }
    ul {
      margin-left: 12px;
      margin-top: 15px;
      li {
        a {
          font-size: 13px;
          display: block;
          padding-block: 12px;
          align-items: center;
          letter-spacing: 0.65px;
          @include hoverOp(.5);
        }
      }
    }
  }
  .banner {
    margin-top: 70px;
    a {
      display: block;
      margin-top: 22px;
      @include mq(tbl){
        @include hoverOp;
      }
    }
  }
}
#sidebar-menu-button {
  position: fixed;
  bottom: var(--space-medium);
  left: var(--space-medium);
  background: top / 48px auto no-repeat url(https://gigaplus.makeshop.jp/matsuotegami/images/icon_sidebar.svg);
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-circle);
  z-index: 2000;
  transition: background-position var(--transition-speed) var(--bezier);
  filter:drop-shadow(3px 3px 4px rgba(91,43,11,0.25));
  &.is-active {
    background-position: center bottom;
  }
  @include mq(tbl){
    display: none;
  }
  span {
    display: none;
  }
}