

$breakpoints: (
  'min': 'print, screen and (max-width: 374px)',
  'sp': 'print, screen and (min-width: 375px)',
  'spl': 'print, screen and (max-width: 768px) and (orientation:landscape)',
  'tb': 'print, screen and (min-width: 768px)',
  'tbl': 'print, screen and (min-width: 1080px)',
  'pc': 'print, screen and (min-width: 1280px)',
) !default;


@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin mq($breakpoint: tbl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
@mixin container($sp:var(--padding-sp),$type:normal) {
  padding-left: $sp;
  padding-right: $sp;
  @include mq(tb) {
    padding-left: calc($sp * 2);
    padding-right: calc($sp * 2);
  }
  @include mq(tbl) {
    padding-left: calc(50% - (var(--width-container-narrow) / 2));
    padding-right: calc(50% - (var(--width-container-narrow) / 2));
  }
  @include mq(pc) {
    padding-left: calc(50% - (var(--width-container) / 2));
    padding-right: calc(50% - (var(--width-container) / 2));
  }
  @if $type == wide {
    @include mq(pc) {
      padding-left: calc(50% - (var(--width-container-wide) / 2));
      padding-right: calc(50% - (var(--width-container-wide) / 2));
    }
  }
  @if $type == narrow {
    @include mq(pc) {
      padding-left: calc(50% - (var(--width-container-narrow) / 2));
      padding-right: calc(50% - (var(--width-container-narrow) / 2));
    }
  }
}

@mixin hoverOp($op:0.7) {
  transition: opacity .3s var(--bezier);
  &:hover {
    opacity: $op;
  }
}
@mixin hoverColor($color:var(--color-brand)) {
  transition: background .3s var(--bezier), color .3s var(--bezier), border .3s var(--bezier);
  &:hover {
    color: $color !important;
  }
}
@mixin hoverBGColor($color:var(--color-brand)) {
  transition: background .3s var(--bezier), color .3s var(--bezier), border .3s var(--bezier);
  &:hover {
    background-color: $color !important;
  }
}
@mixin fs($fts, $lin: 14,$ls:0) {
  $rem: calc($fts / 10);
  $linS: calc($lin / $fts);
  $lsm: calc($ls * 0.001);
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
  letter-spacing: #{$lsm}em;
  
}
@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: unset;
    margin: 0 4px;
    button {
      border: none;
      background-color: var(--bg-default);
      color: transparent;
      height: $height;
      width: $width;
      padding: unset;
      cursor: pointer;
      border-radius: var(--border-radius-circle);
    }
    &.slick-active {
      button {
        background-color:var(--bg-third);
      }
    }
  }
}

@mixin grid($cols,$gap-vert,$gap-horiz) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: $gap-vert $gap-horiz;
}

@mixin caption {
  text-align: center;
  margin-top: var(--space-small);
  color: var(--color-text);
  font-size: var(--font-size-xxsmall);
  line-height: var(--line-height-medium);
  @include mq(tbl) {
    font-size: var(--font-size-xsmall);
  }
  &.shrink {
    margin-left: auto;
    margin-right: auto;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}
@mixin heading {
  font-family: var(--font-family-heading);
}
@mixin headingMedium {
  position: relative;
  padding-bottom: var(--space-xsmall);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  font-family: var(--font-family-heading);
  display: inline-block;
  word-break: auto-phrase;
  @include mq(tbl) {
    padding-bottom: var(--space-xsmall);
    font-size: 2rem;
  }
  &:before {
    @include pd;
    width: 70px;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--fg-default);
    z-index: 2;
  }
}
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
@function vw($px, $base: 375px) {
  $value: $px;
  @if (unit($px) != 'px') {
    @warn 'vw()の引数にpx以外の値を指定しても計算できません';
    @return $value;
  }
  $value: calc(strip-unit($px) / strip-unit($base)) * 100vw;
  @return $value;
}
@function pc($px, $base: 1280px) {
  $value: $px;
  @if (unit($px) != 'px') {
    @warn 'vw()の引数にpx以外の値を指定しても計算できません';
    @return $value;
  }
  $value: calc(strip-unit($px) / strip-unit($base)) * 100vw;
  @return min($px, $value);
}