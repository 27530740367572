@charset "UTF-8";

@import "mixins";

.navigation.pagenate_detail {
  @include container;
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    padding-top: var(--space-xxlarge);
    padding-bottom: var(--space-xxxlarge);
    position: relative;
    border-top: 1px solid var(--color-border);
    display: flex;
    justify-content: space-between;
    @include mq(tbl) {
      padding-top: var(--space-xxxlarge);
      padding-bottom: 0;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }
    span {
      display: inline-block;
      a {
        display: inline-block;
        position: relative;
        text-align: center;
        @include mq(tbl) {
          margin: 0 var(--space-medium);
        }
        svg {
          display: none;
        }
      }
      &.back {
        position: absolute;
        bottom: 0;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        @include mq(tbl) {
          position: static;
          transform: translateX(0);
        }
        a {
          &:before {
            content: none;
          }
        }
      }
      &.prev {
        @include mq {
          justify-self: end;
        }
        a {
          &:before {
            left: 16px;
            right: auto;
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      &.next {
        a {
          &:before {
            right: 16px;
          }
        }
      }
    }
  }
}
