@charset "UTF-8";

@import "mixins";

.static.heading_link {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.set2 {
    .inner {
      @include mq(tbl) {
        @include grid(2,var(--gap-2col-col),var(--gap-2col-row));
      }
      .unit {
        @include mq(tbl) {
          flex: 1;
          display: grid;
          grid-template-rows: subgrid;
          grid-row: span 4;
          gap: 0;
        }
        ul {
          @include mq(tbl) {
            column-count: 1;
          }
        }
      }
    }
  }
  .inner {
    @include container;
    .unit {
      + .unit {
        margin-top: var(--margin-top-medium);
        @include mq(tbl) {
          margin-top: unset;
        }
      }
      h3 {
        font-size: var(--font-size-large);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-heading);
        font-family: var(--font-family-heading);
        @include mq(tbl) {
          font-size: var(--font-size-xlarge);
        }
      }
      p {
        font-size: var(--font-size-xsmall);
        line-height: var(--line-height-medium);
        margin-top: var(--space-medium);
        @include mq(tbl) {
          margin-top: var(--space-large);
          font-size: var(--font-size-small);
        }
      }
      ul {
        margin-top: var(--space-medium);
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        padding: var(--space-small) 0 var(--space-large);
        @include mq(tbl) {
          padding: var(--space-large) 0 var(--space-large);
          margin-top: var(--space-large);
          column-count: 3;
          column-rule: solid 1px var(--color-border);
          column-gap: 7%;
        }
        li {
          font-size: var(--font-size-xsmall);
          margin-top: var(--space-small);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            margin-top: var(--space-small);
            font-size: var(--font-size-small);
          }
          a {
            position: relative;
            display: block;
            padding-left: var(--space-medium);
            color: var(--color-text);
            text-decoration: underline;
            @include mq(tbl) {
              padding-left: var(--space-large);
              &:hover {
                text-decoration: none;
              }
            }
            &:before {
              @include pd;
              left: 0;
              top: 7px;
              background: center / contain no-repeat var(--arrow-right);
              width: 8px;
              height: 8px;
              @include mq(tbl) {
                width: 12px;
                height: 12px;
                top: 5px;
              }
            }
          }
        }
      }
      .link {
        margin-top: var(--space-large);
        text-align: right;
        @include mq(tbl) {
          margin-top: var(--space-xlarge);
        }
        a {
          text-align: center;
        }
      }
    }
  }
}
